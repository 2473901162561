import React, { useEffect } from "react";
import { useParams,useLocation } from "react-router-dom";
import $ from 'jquery';
const API_URL = process.env.REACT_APP_apiUrl;

export default function MakePayment(props) {
  const params = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  useEffect(() => {
    const loadScript = (src, id, callback) => {
      if (!document.getElementById(id)) {
        const script = document.createElement('script');
        script.src = src;
        script.id = id;
        script.onload = callback;
        document.body.appendChild(script);
      } else if (callback) {
        callback();
      }
    };

    const initializePayment = () => {
      function handleResponse(res) {
        if (

          typeof res !== 'undefined' &&
          typeof res.paymentMethod !== 'undefined' &&
          typeof res.paymentMethod.paymentTransaction !== 'undefined' &&
          typeof res.paymentMethod.paymentTransaction.statusCode !== 'undefined'
        ) {
          console.log(res)
          if (res.paymentMethod.paymentTransaction.statusCode === '0300') {
            // success block
            console.log('Payment Successful');
          } else if (res.paymentMethod.paymentTransaction.statusCode === '0398') {
            // initiated block
            console.log('Payment Initiated');
          } else {
            // error block
            console.log('Payment Error');
          }
        }
        else {
          console.log(res, "@@@")
        }
      }

      const handlePayment = (e) => {
        e.preventDefault();

        const reqJson = {
          features: {
            enableAbortResponse: true,
            enableExpressPay: true,
            enableInstrumentDeRegistration: true,
            enableMerTxnDetails: true,
          },
          consumerData: {
            deviceId: 'WEBSH2', // possible values "WEBSH1" or "WEBSH2"
            token:params.merchantRequest,
            returnUrl:searchParams.get("returnurl"), // merchant response page URL
            responseHandler: handleResponse,
            paymentMode: 'all',
            merchantLogoUrl:
              'https://www.paynimo.com/CompanyDocs/company-logo-vertical.png', // provided merchant logo will be displayed
            merchantId: `${params.mid}`,
            currency: 'INR',
            // consumerId: 'c964634',
            txnId: searchParams.get("txnId"), // Unique merchant transaction ID
            items: [
              {
                itemId: 'FIRST',
                amount: `${searchParams.get("amount")}`,
                comAmt: '0',
              },
            ],
            customStyle: {
              PRIMARY_COLOR_CODE: '#45beaa', // merchant primary color code
              SECONDARY_COLOR_CODE: '#FFFFFF', // provide merchant's suitable color code
              BUTTON_COLOR_CODE_1: '#2d8c8c', // merchant's button background color code
              BUTTON_COLOR_CODE_2: '#FFFFFF', // provide merchant's suitable color code for button text
            },
          },
        };

        window.$.pnCheckout(reqJson);
        // if (reqJson.features.enableNewWindowFlow) {
        // pnCheckoutShared.openNewWindow();
        // }
      };

      $('#btnSubmit').off('click').on('click', handlePayment);
    };

    const ensureCheckoutScriptLoaded = () => {
      if (window.$ && window.$.pnCheckout) {
        initializePayment();
      } else {
        setTimeout(ensureCheckoutScriptLoaded, 100);
      }
    };

    // Load jQuery and checkout.js
    loadScript('https://www.paynimo.com/paynimocheckout/client/lib/jquery.min.js', 'jquery-script', () => {
      console.log('jQuery loaded');
      loadScript('https://www.paynimo.com/paynimocheckout/server/lib/checkout.js', 'checkout-script', ensureCheckoutScriptLoaded);
    });

  }, []);


  return (
    <div>
      {/* loading ... */}
      <button id="btnSubmit">Proceed to Pay</button>

    </div>
  );
}
