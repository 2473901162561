// @flow

import React from "react";
import {
    Grid,
    Card,
    Table,
    Page,
    StatsCard,
    Form, Button
} from "tabler-react";
import "tabler-react/dist/Tabler.css";
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
// import SiteWrapper from "../SiteWrapper.client.react";
import SiteWrapperSuperAdmin from "../SiteWrapper.superadmin.react";
import authActions from "../../redux/auth/action";
import clientService from "../../services/client";
import DownloadPopup from './downloadPopup';
import FeedBackAnalytics from "./FeedBackAnalytics";
import C3Chart from "react-c3js";
import '../../c3jscustom.css';
import 'c3/c3.css';
import * as d3 from 'd3';
import ReactWordcloud from 'react-wordcloud';
import words from "./words";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import PaymentLogs from "./Analytic.PaymentLogs";
const _ = require('lodash');
const moment = require('moment');



class Analytic extends React.Component {


    constructor(props) {
        let dd = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).setHours(0, 0, 0);
        let dd2 = new Date().setHours(23, 59, 59);
        let dataChart = {
            bindto: '#graph2',
            x: 'x',
            columns: [
                ['x'],
                ['Total Users'],
                ['Total Unique Users']
            ],
            colors: {
                'Total Users': '#0A49B6',
                "Total Unique Users": '#fd9644'
            },

        }
        super(props)
        this.state = {

            startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            endDate: new Date(),
            startDateBar: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            endDateBar: new Date(),
            filterStartDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US'),
            filterEndDate: new Date().toLocaleDateString('en-US'),
            filterStartDateBar: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US'),
            filterEndDateBar: new Date().toLocaleDateString('en-US'),

            startDateSingleBar: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            endDateSingleBar: new Date(),
            filterStartDateSingleBar: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US'),
            filterEndDateSingleBar: new Date().toLocaleDateString('en-US'),


            startDateSingleBarHorizontal: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            endDateSingleBarHorizontal: new Date(),
            filterStartDateSingleBarHorizontal: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US'),
            filterEndDateSingleBarHorizontal: new Date().toLocaleDateString('en-US'),

            contextUniqueData: [],
            contextData: [],
            xAxisData: [],
            countDataUnique: [],
            countDataContext: [],
            sortValue: '',
            sortValueBar: '',
            userUniqueData: [],
            userData: [],
            userXAxisData: [],
            countDataUniqueUser: [],
            countDataUser: [],
            contextKey: [],
            dashboardData: '',
            openDownloadPopup: false,
            chartDataValue: dataChart,
            contextNameArr: [],
            graphStatus: true,
            allContextData: [],
            notificationContextData: [],
            allNotificationAiData: [],
            notificationAiData: [],
            notificationXAxix: [],
            contextKeyNotification: [],

            allNotificationAgentData: [],
            notificationAgentData: [],
            notificationAgentXAxix: [],
            avgCount: 0,
            words: [],
            NPS_MeterData: {
                columns: [["NPS", 0]],
                type: "gauge",
            },
            NPS_BarData: {
                columns: [
                    ["Value", 0, 0, 0],
                ],
                color: function (inColor, data) {
                    var colors = ["green", "orange", "red"];
                    if (data.index !== undefined) {
                        return colors[data.index];
                    }
                    return inColor;
                },
                type: "bar",
            },
            hospitalsList: ["ghubra", "sohar", "ibri"],
            reportLogs: {
                columns: [
                    ['Failure', 0],
                    ['Success', 0],
                ],
                type: 'donut',
            },
            startDateForReportLogs: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            endDateForReportLogs: new Date(),

            startDateForPaymentLogs: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            endDateForPaymentLogs: new Date(),
        }
        this.closeDownloadPopup = this.closeDownloadPopup.bind(this);
        this.handlerAllChatDateChanger = this.handlerAllChatDateChanger.bind(this)
    }

    closeDownloadPopup(data) {

        this.setState({
            openDownloadPopup: {
                open: false,
                data: false
            }
        })
    }

    openDownloadPopup(e, data) {
        this.setState({
            openDownloadPopup: {
                open: true,
                data: data,
            }
        })
    }

    async callServiceUnique(startDate, endDate) {

        if ((startDate != '' || startDate) && (endDate != '' || endDate)) {

            await clientService.getChartData(startDate, endDate, true, this.state.contextKey)
                .then(async (res) => {
                    let data = res.data
                    data.forEach(d => {
                        d.dateObj = moment(d._id);
                    });
                    await this.setState({ contextUniqueData: data })
                })

            await clientService.getChartData(startDate, endDate, false, this.state.contextKey)
                .then(async (res) => {
                    let data = res.data
                    data.forEach(d => {
                        d.dateObj = moment(d._id);
                    });
                    await this.setState({ contextData: data })
                })

            let contextUniqueData = await this.state.contextUniqueData

            let contextData = await this.state.contextData
            let xAxisData = await contextUniqueData && contextUniqueData.length > 0 ? contextUniqueData.map(a => a._id) : [];
            let countDataUnique = await contextUniqueData && contextUniqueData.length > 0 && contextUniqueData.map(a => a.count);
            let countDataContext = await contextData && contextData.length > 0 && contextData.map(a => a.count);

            await this.setState({ xAxisData, countDataUnique, countDataContext })
            await this.getDataLine();
        }

    }

    millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    }


    buildData(data, keyName) {
        let result = [];
        _.forEach(data, (val, key) => {
            let totalCounts = val.reduce((acc, curr) => {
                return acc + curr.count;
            }, 0)
            console.log('keykey', key);
            result.push({ [keyName]: key, count: totalCounts })
        })
        return result;
    }

    groupAndBuild(data, dateMethod, groupKey) {
        let groupedData = _.groupBy(data, (d) => {
            return d.dateObj[dateMethod]()
        })
        console.log('groupedData', groupedData);
        return this.buildData(groupedData, groupKey)
    }

    componentDidMount() {
        setTimeout(() => {

            clientService.getContext().then(res => {
                let notificationContextData = res.data.config.contexts.filter((value) => {
                    return (value.key === 'book_home_collection'
                        || value.key === 'book_health_packages'
                        || value.key === 'locate_and_book_test'
                        // || value.key === 'checkout_report'
                        // || value.key === 'request_callback'
                        || value.key === 'franchise_inquiry'
                        || value.key === 'book_appointment'
                    )
                })

                this.setState({ allContextData: res.data.config.contexts, notificationContextData })
            })


            console.log('filterStartDate', this.state.filterStartDate);
            document.title = "Voiceoc | Analytics";
            this.callServiceUnique(this.state.filterStartDate, this.state.filterEndDate)
            this.callServiceUniqueBar(this.state.filterStartDateBar, this.state.filterEndDateBar)
            clientService.getCountData().then(res => {
                this.setState({ dashboardData: res.data.data })
            })
            this.callNotificationService(this.state.filterStartDateSingleBar, this.state.filterEndDateSingleBar, 'ai')
            this.callNotificationServiceAgent(this.state.filterStartDateSingleBar, this.state.filterEndDateSingleBar, 'agent')
            clientService.getAvgCst().then((res) => {
                this.setState({
                    avgCount: res.data.avgCst
                })
            })
            this.getNPS_MeterData(
                this.state.startDateForNPS_Data,
                this.state.endDateForNPS_Data,
                this.state.selectedHospitalForNPS_Data,
                this.state.selectedDepartmentForNPS_Data
            );
            this.getWordData(
                this.state.startDateForWordCloud,
                this.state.endDateForWordCloud,
                this.state.selectedHospitalForWordCloud,
                this.state.selectedDepartmentForWordCloud,
                this.state.selectedLanguageForWordCloud
            );
            this.getReportLogs(new Date(), new Date());

        }, 1000)
    }


    exportData() {
        var url = process.env.REACT_APP_apiUrl + 'bot/download-chats';
        window.open(url, '_blank');
    }



    getDataLine() {

        if (this.state.xAxisData.length) {

            let data = {
                bindto: '#graph2',
                // unload:true,
                x: 'x',
                columns: [
                    ['x', ...this.state.xAxisData],
                    ['Total Users', ...this.state.countDataContext],
                    ['Total Unique Users', ...this.state.countDataUnique]
                ],
                // unload: ['Total Users', 'Total Unique Users'],
                colors: {
                    'Total Users': '#0A49B6',
                    "Total Unique Users": '#fd9644'
                },

            }

            this.setState({
                chartDataValue: data
            })
            // return data
        } else {

            let data = {
                bindto: '#graph2',
                x: 'x',
                // unload:true,
                columns: [
                    ['x', null],
                    ['Total Users', null],
                    ['Total Unique Users', null]
                ],
                colors: {
                    'Total Users': '#0A49B6',
                    "Total Unique Users": '#fd9644'
                },
            }

            this.setState({
                chartDataValue: data
            })
            // return data
        }
    }
    async getReportLogs(startDate, endDate) {
        var data = {
            columns: [
                ['Failure', 0],
                ['Success', 0],
            ],
            type: 'donut',
        }
        console.log("REPORT", startDate, endDate)
        await clientService.getReportLogs(startDate, endDate).then(res => {
            console.log("res.data", res.data)
            if (res.data.length > 0) {
                let col = res.data.map(m => ([m.status.charAt(0).toUpperCase() + m.status.slice(1), m.count]));
                console.log({ col })
                data.columns = col;
                console.log(data)
            }
            this.setState({ reportLogs: data })
        }).catch(err => {
            console.log(err);
        })
    }

    async getPaymentLogs(startDate, endDate) {
        var data = {
            columns: [
                ['Failure', 0],
                ['Success', 0],
            ],
            type: 'donut',
        }
        console.log(startDate, endDate)
        // await clientService.getReportLogs(startDate, endDate).then(res => {
        //     if (res.data.length > 0) {
        //         let col = res.data.map(m => ([m.status.charAt(0).toUpperCase() + m.status.slice(1), m.count]));
        //         data.columns = col;
        //     }
        // }).catch(err => {
        //     console.log(err);
        // })
        // this.setState({ reportLogs: data })
    }

    handlerAllChatDateChanger(type, event) {
        if (type === 'endDateAllChat') {
            this.setState({ endDateForAllChat: event })
        }
        if (type === 'startDateAllChat') {
            this.setState({ startDateForAllChat: event })
        }
        if (type === "startDateAnswerChatGraph") {
            this.setState({ startDateForAnswerChatGraph: event })
        }
        if (type === "endDateAnswerChatGraph") {
            this.setState({ endDateForAnswerChatGraph: event })
        }
        if (type === "startDateForReportLogs") {
            this.setState({ startDateForReportLogs: event })
        }
        if (type === "endDateForReportLogs") {
            this.setState({ endDateForReportLogs: event })
        }
        if (type === "startDateForPayment") {
            this.setState({ startDateForPaymentLogs: event })
        }
        if (type === "endDateForPayment") {
            this.setState({ endDateForPaymentLogs: event })
        }


    }



    tooltip_contentsForReportLogs(d, defaultTitleFormat, defaultValueFormat, color) {
        // console.log(d,defaultTitleFormat,defaultTitleFormat,color);
        var $$ = this, config = $$.config, CLASS = $$.CLASS,
            titleFormat = config.tooltip_format_title || defaultTitleFormat,
            nameFormat = config.tooltip_format_name || function (name) { return name; },
            valueFormat = config.tooltip_format_value || defaultValueFormat,
            text, i, title, value, name, bgcolor;

        for (i = 0; i < d.length; i++) {
            if (!(d[i] && (d[i].value || d[i].value === 0))) { continue; }

            // ADD
            if (d[i].name === 'data2') { continue; }


            if (!text) {
                title = 'MY TOOLTIP'
                text = "<table class='" + CLASS.tooltip + "'>" + (title || title === 0 ? "<tr><th colspan='2'>" + d[i].name + "</th></tr>" : "");
            }
            name = nameFormat(d[i].name);
            value = valueFormat(d[i].value, d[i].ratio, d[i].id, d[i].index);
            bgcolor = $$.levelColor ? $$.levelColor(d[i].value) : color(d[i].id);

            text += "<tr class='" + CLASS.tooltipName + "-" + d[i].id + "'>";
            text += "<td class='name'><span style='background-color:" + bgcolor + "'></span>" + "Percentage" + "</td>";
            text += "<td class='value'>" + value + "</td>";
            text += "</tr>";

            text += "<tr class='" + CLASS.tooltipName + "-" + d[i].id + "'>";
            text += "<td class='name'><span style='background-color:" + bgcolor + "'></span>" + "Count" + "</td>";
            text += "<td class='value'>" + d[i].value + "</td>";
            text += "</tr>";
        }
        return text + "</table>";
    }
    getAxisLine() {
        let axis = {
            x: {
                type: 'category',
                tick: {
                    // precision: 0,
                    beginAtZero: true,
                    // rotate: 75,
                    rotate: 75,
                    multiline: false
                },
                height: 130,
                unload: true
            },
            y: {
                tick: {
                    format: function (d) {
                        return (parseInt(d) == d) ? d : null;
                    },
                    // precision: 0,
                    // beginAtZero: true,
                },
                // show: true,
                unload: true
            }
        }
        return axis
    }

    getAxisLineBar() {
        let axis = {
            x: {
                type: 'category',
                tick: {
                    // rotate: 75,
                    multiline: false
                },
                height: 130
            },
        }
        return axis
    }
    getDataBar() {
        console.log('userXAxisData9999', this.state.userXAxisData)
        // console.log('userXAxisData', this.state.userXAxisData, 'getDataBar0', this.state.countDataUser, 'getDataBar1', ...this.state.countDataUniqueUser);
        if (this.state.userXAxisData.length > 0) {

            let data = {
                bindto: '#graph1',
                x: 'x',
                columns: [
                    ['x', ...this.state.userXAxisData],
                    ['Total Users', ...this.state.countDataUser],
                    ['Total Unique Users', ...this.state.countDataUniqueUser]
                ],
                type: 'bar',
                colors: {
                    'Total Users': '#662C8F',
                    "Total Unique Users": '#EC4697'

                },
                // ['Total Users', 'Total Unique Users']
                // groups: [
                //     ['download', 'uploading']
                // ],
            }

            return data
        } else {
            let data = {
                bindto: '#graph1',
                x: 'x',
                columns: [
                    // ['x', "Appointment Request", "Locate Clinic", "Callback Request"],
                    ['x'],
                    ['Total Users'],
                    ['Total Unique Users']
                ],
                type: 'bar',
                // colors: {
                //     'Total Users': '#662C8F',
                //     "Total Unique Users": '#EC4697'

                // },
            }
            return data
        }


    }


    getBar() {
        let bar = {
            width: {
                ratio: 0.5 // this makes bar width 50% of length between ticks
            }
        }
        return bar
    }
    getAxisBar() {
        let axis = {
            x: {
                type: 'category',

                // tick: {
                //     format: '%Y-%m-%d'
                // }
            }
        }

        return axis
    }


    getDataSingleBar() {
        console.log('this.state.notificationXAxix', this.state.notificationXAxix)
        console.log('this.state.notificationXAxix', this.state.notificationAiData)
        if (this.state.notificationXAxix.length > 0) {

            let data = {
                bindto: '#graph3',
                x: 'x',
                columns: [
                    // ['x', ...this.state.userXAxisData],
                    ['x', ...this.state.notificationXAxix],
                    ['Total Users', ...this.state.notificationAiData],

                ],
                type: 'bar',
                colors: {
                    'Total Users': '#43702f',

                },
                // ['Total Users', 'Total Unique Users']
                // groups: [
                //     ['download', 'uploading']
                // ],
            }

            return data
        } else {
            let data = {
                bindto: '#graph3',
                x: 'x',
                columns: [
                    // ['x', "Appointment Request", "Locate Clinic", "Callback Request"],
                    ['x', null],
                    ['Total Users', null],
                ],
                type: 'bar',
                // colors: {
                //     'Total Users': '#662C8F',
                //     "Total Unique Users": '#EC4697'

                // },
            }
            return data
        }


    }
    async sortGraph(value) {
        // debugger
        if (value) {
            await this.setState({ sortValue: value })
        }
        console.log('sortValuesortValue', this.state.sortValue);
        if (this.state.sortValue === 'byWeek' && this.state.contextUniqueData.length > 0 && this.state.contextData.length > 0) {
            let contextUniqueData = this.groupAndBuild(this.state.contextUniqueData, 'week', 'week')
            let contextData = this.groupAndBuild(this.state.contextData, 'week', 'week')
            let newData = contextUniqueData.map(el => {
                if (el.week)
                    return Object.assign({}, el, { week: 'week-' + el.week })
                return el
            });

            let xAxisData = []
            let countDataUnique = []
            let countDataContext = []
            xAxisData = newData && newData.length > 0 && newData.map(a => a.week);
            countDataUnique = newData && newData.length > 0 && newData.map(a => a.count);
            countDataContext = contextData && contextData.length > 0 && contextData.map(a => a.count);

            await this.setState({ xAxisData, countDataUnique, countDataContext })
            await this.getDataLine();

        } else if (this.state.sortValue === 'byMonth' && this.state.contextUniqueData.length > 0 && this.state.contextData.length > 0) {
            console.log('sortGraph240');
            let contextUniqueData = this.groupAndBuild(this.state.contextUniqueData, 'month', 'month')
            let contextData = this.groupAndBuild(this.state.contextData, 'month', 'month');
            let newData = contextUniqueData.map(el => {
                if (el.month)
                    return Object.assign({}, el, { month: 'month-' + (parseInt(el.month) + 1) })
                return el
            });
            let xAxisData = newData && newData.length > 0 && newData.map(a => a.month);
            let countDataUnique = newData && newData.length > 0 && newData.map(a => a.count);
            let countDataContext = contextData && contextData.length > 0 && contextData.map(a => a.count);
            await this.setState({ xAxisData, countDataUnique, countDataContext })
            await this.getDataLine();
        } else {
            console.log('sortGraph253');
            this.callServiceUnique(this.state.filterStartDate, this.state.filterEndDate)
        }
    }
    handleChange(type, date) {
        console.log('datedate', date);
        let changeDate = new Date(date).toLocaleDateString('en-US')
        console.log('datedatechangeDate', changeDate);
        this.setState({ graphStatus: true })
        this.setState({
            [type]: date
        });
        if (type === 'startDate') {
            this.setState({ filterStartDate: changeDate })
        } else {
            this.setState({ filterEndDate: changeDate })
        }

    }

    submitData() {

    }

    async multiselect(data) {
        // debugger
        let checkData = this.state.contextKey.indexOf(data);
        if (checkData < 0) {
            await this.setState({ contextKey: this.state.contextKey.concat(data) })
            await this.callServiceUnique(this.state.filterStartDate, this.state.filterEndDate)
            await this.setState({ sortValue: 'byDay' })
            await this.getDataLine();
        } else {
            const array = await this.state.contextKey;
            const index = await array.indexOf(data);
            if (index > -1) {
                await array.splice(index, 1);
                console.log('newListinside', array);
            }

            console.log('newList', array);
            let filtered = await array.filter(function (el) {
                return el != null;
            });
            console.log('newList--', filtered);
            await this.setState({ contextKey: filtered })
            await this.callServiceUnique(this.state.filterStartDate, this.state.filterEndDate)
            await this.setState({ sortValue: 'byDay' })
            await this.getDataLine();
        }
    }
    handleSubmit = async event => {
        event.preventDefault();
        // this.callService(this.state.filterStartDate, this.state.filterEndDate)
        this.setState({ sortValue: 'byDay' })
        this.callServiceUnique(this.state.filterStartDate, this.state.filterEndDate)
    }

    exportData() {
        var url = process.env.REACT_APP_apiUrl + 'bot/download-chats';
        window.open(url, '_blank');
    }


    async callServiceUniqueBar(startDate, endDate) {
        clientService.getChartUserData(startDate, endDate, true)
            .then(async (res) => {
                let data = res.data
                await this.setState({ userUniqueData: data })
            }).catch(err => console.error(err))

        await clientService.getChartUserData(startDate, endDate, false)
            .then(async (res) => {
                let data = res.data
                await this.setState({ userData: data })
            }).catch(err => console.error(err))
        let userUniqueData = this.state.userUniqueData
        let userData = this.state.userData

        let userXAxisData = userUniqueData && userUniqueData.length > 0 && userUniqueData.map(a => a.contextName);
        let countDataUniqueUser = userUniqueData && userUniqueData.length > 0 && userUniqueData.map(a => a.count);
        let countDataUser = userData && userData.length > 0 && userData.map(a => a.count);
        this.setState({ userXAxisData, countDataUniqueUser, countDataUser })
    }


    sortGraphBar(e) {

        this.setState({ sortValueBar: e.target.value })
        if (e.target.value === 'byWeek' && this.state.userUniqueData.length > 0 && this.state.userData.length > 0) {

            let userUniqueData = this.groupAndBuild(this.state.userUniqueData, 'week', 'week')
            let userData = this.groupAndBuild(this.state.userData, 'week', 'week')
            let newData = userUniqueData.map(el => {
                if (el.week)
                    return Object.assign({}, el, { week: 'week-' + el.week })
                return el
            });

            let userXAxisData = []
            let countDataUniqueUser = []
            let countDataUser = []
            userXAxisData = newData && newData.length > 0 && newData.map(a => a.week);
            countDataUniqueUser = newData && newData.length > 0 && newData.map(a => a.count);
            countDataUser = userData && userData.length > 0 && userData.map(a => a.count);

            this.setState({ userXAxisData, countDataUniqueUser, countDataUser })

        } else if (e.target.value === 'byMonth' && this.state.userUniqueData.length > 0 && this.state.userData.length > 0) {
            let userUniqueData = this.groupAndBuild(this.state.userUniqueData, 'month', 'month')
            let userData = this.groupAndBuild(this.state.userData, 'month', 'month');
            let newData = userUniqueData.map(el => {
                if (el.month)
                    return Object.assign({}, el, { month: 'month-' + el.month })
                return el
            });
            let userXAxisData = newData && newData.length > 0 && newData.map(a => a.month);
            let countDataUniqueUser = newData && newData.length > 0 && newData.map(a => a.count);
            let countDataUser = userData && userData.length > 0 && userData.map(a => a.count);
            this.setState({ userXAxisData, countDataUniqueUser, countDataUser })
        } else {
            this.callServiceUniqueBar(this.state.filterStartDateBar, this.state.filterEndDateBar)
        }

    }

    handleChangeBar(type, date) {
        console.log('datedate', date)
        // debugger
        let changeDate = new Date(date).toLocaleDateString('en-US')
        // console.log('datedate---',changeDate)

        this.setState({ graphStatus: false })
        this.setState({
            [type]: date
        });
        if (type === 'startDateBar') {
            this.setState({ filterStartDateBar: changeDate })
        } else {
            this.setState({ filterEndDateBar: changeDate })
        }

    }

    handleSubmitBar = async event => {
        event.preventDefault();
        // this.callService(this.state.filterStartDate, this.state.filterEndDate)
        this.setState({ sortValue: 'byDay' })
        this.callServiceUniqueBar(this.state.filterStartDateBar, this.state.filterEndDateBar)
    }

    handleChangeSingleBar(type, date) {
        console.log('datedate', date)
        // debugger
        let changeDate = new Date(date).toLocaleDateString('en-US')
        // console.log('datedate---',changeDate)

        // this.setState({graphStatus:false})
        this.setState({
            [type]: date
        });
        if (type === 'startDateSingleBar') {
            this.setState({ filterStartDateSingleBar: changeDate })
        } else {
            this.setState({ filterEndDateSingleBar: changeDate })
        }

    }

    async callNotificationService(startDate, endDate, type, context) {
        await clientService.getfeedBack(startDate, endDate, type, context).
            then((res) => {
                // console.log('resresres---',res.data)
                let data = res.data
                let arrRating = []
                let rating = [1, 2, 3, 4, 5]
                rating.forEach((item) => {
                    console.log('itemitem', item)
                    let rating1 = data.find(x => x.rating == item)

                    if (rating1) {
                        arrRating.push(rating1)
                    } else {
                        arrRating.push({ rating: item, count: 0 })
                    }
                })
                console.log('arrRatingarrRating', arrRating)
                this.setState({
                    allNotificationAiData: arrRating
                })
            })

        let allData = this.state.allNotificationAiData;
        let notificationAiData = allData && allData.length > 0 && allData.map(a => a.count);
        let notificationXAxix = allData && allData.length > 0 && allData.map(a => a.rating);
        this.setState({ notificationAiData, notificationXAxix })
        await this.getDataSingleBar()
    }

    async callNotificationServiceAgent(startDate, endDate, type) {
        await clientService.getfeedBack(startDate, endDate, type).
            then(async (res) => {
                this.setState({
                    allNotificationAgentData: res.data
                })
                let allData = res.data;
                let notificationAgentData = await allData && allData.length > 0 && allData.map(a => a.name);
                let notificationAgentXAxix = await allData && allData.length > 0 && allData.map(a => a.averageQuantity);
                await this.setState({ notificationAgentData, notificationAgentXAxix })
                await this.getDataSingleBarHorizontal()
            }).catch(err => console.error(err))

    }
    handleSubmitSingleBar = async event => {
        event.preventDefault();

        this.callNotificationService(this.state.filterStartDateSingleBar, this.state.filterEndDateSingleBar, 'ai', this.state.contextKeyNotification)
    }

    async multiselectSingle(data) {
        // debugger
        let checkData = this.state.contextKeyNotification.indexOf(data);
        if (checkData < 0) {
            await this.setState({ contextKeyNotification: this.state.contextKeyNotification.concat(data) })
            await this.callNotificationService(this.state.filterStartDateSingleBar, this.state.filterEndDateSingleBar, 'ai', this.state.contextKeyNotification)
            // await this.setState({ sortValue: 'byDay' })
            // await this.getDataLine();
        } else {
            const array = await this.state.contextKeyNotification;
            const index = await array.indexOf(data);
            if (index > -1) {
                await array.splice(index, 1);
            }

            let filtered = await array.filter(function (el) {
                return el != null;
            });
            await this.setState({ contextKeyNotification: filtered })
            await this.callNotificationService(this.state.filterStartDateSingleBar, this.state.filterEndDateSingleBar, 'ai', this.state.contextKeyNotification)
            await this.getDataSingleBar()
            // await this.setState({ sortValue: 'byDay' })
            // await this.getDataLine();
        }
    }

    async getWordData(startDate, endDate, hospital, department, language) {
        console.log(startDate, endDate, hospital, department, language);
        if (startDate && endDate && startDate !== "" && endDate !== "") {
            await clientService.getWordData(startDate, endDate, hospital, department, language).then((res) => {
                if (res.message === "Success" && res.statusCode === 200) {
                    this.setState({
                        words: res.data.map((m) => ({ text: m._id, value: m.count })),
                    });
                }
            }).catch(err => console.error(err));
        }
    }
    async getNPS_MeterData(startDate, endDate, hospital, department) {
        if (startDate && endDate && startDate !== "" && endDate !== "") {
            await clientService.getNPSData(startDate, endDate, hospital, department).then((res) => {
                if (res.message === "Success" && res.statusCode === 200 && res.data) {
                    console.log(res.data);
                    let MeterData = {
                        columns: [["NPS", res.data.nps]],
                        type: "gauge",
                    };
                    let barData;
                    if (Array.isArray(res.data.response1)) {
                        let promotors = 0;
                        let passives = 0;
                        let distarctors = 0;
                        res.data.response1.forEach(element => {
                            promotors = promotors + element.promotors;
                            passives = passives + element.passives;
                            distarctors = distarctors + element.distarctors;
                        });
                        if (res.data.response1.length > 0) {
                            barData = {
                                columns: [
                                    // ["x", "Promoters", "Passives", "Detractors"],
                                    ["Value", promotors, passives, distarctors,],
                                ],
                                type: "bar",
                                color: {
                                    pattern: ["green", "orange", "red"],
                                },
                                color: function (inColor, data) {
                                    var colors = ["green", "orange", "red"];
                                    if (data.index !== undefined) {
                                        return colors[data.index];
                                    }
                                    return inColor;
                                },
                                x: "Value",
                            }
                        } else {
                            barData = {
                                columns: [
                                    // ["x", "Promoters", "Passives", "Detractors"],
                                    ["Value", 0, 0, 0],
                                ],
                                type: "bar",
                                // color: {
                                //     pattern: ["green", "orange", "red"],
                                // },
                                color: function (inColor, data) {
                                    var colors = ["green", "orange", "red"];
                                    if (data.index !== undefined) {
                                        return colors[data.index];
                                    }
                                    return inColor;
                                },
                                x: "Value",
                            }
                        }

                    } else {
                        barData = {
                            columns: [
                                ["Value",
                                    res.data.response1.promotors,
                                    res.data.response1.passives,
                                    res.data.response1.distarctors,
                                ],
                            ],
                            type: "bar",
                            // color: {
                            //     pattern: ["green", "orange", "red"],
                            // },
                            color: function (inColor, data) {
                                var colors = ["green", "orange", "red"];
                                if (data.index !== undefined) {
                                    return colors[data.index];
                                }
                                return inColor;
                            },
                            x: "Value",
                        }
                    }
                    this.setState({
                        NPS_MeterData: MeterData,
                    });
                    this.setState({
                        NPS_BarData: barData,
                    });
                }
            }).catch(err => console.error(err));
        }
    }
    async onSelectDepartmentForNPS(data) {
        if (this.state.selectedDepartmentForNPS_Data === data) {
            this.setState({ selectedDepartmentForNPS_Data: '' });
            setTimeout(() => {
                document.querySelectorAll(".notSelectedPill input").forEach(ele => {
                    ele.checked = false;
                })
            }, 100);
            this.getNPS_MeterData(
                this.state.startDateForNPS_Data,
                this.state.endDateForNPS_Data,
                this.state.selectedHospitalForNPS_Data,
                ""
            );
            return;
        }
        setTimeout(() => {
            document.querySelectorAll(".notSelectedPill input").forEach(ele => {
                ele.checked = false;
            })
        }, 100);
        console.log(data);
        this.setState({ selectedDepartmentForNPS_Data: data });
        this.getNPS_MeterData(
            this.state.startDateForNPS_Data,
            this.state.endDateForNPS_Data,
            this.state.selectedHospitalForNPS_Data,
            data
        );

    }
    async multiselectDeparmentForNetPromoter(data) {
        let arr = [...this.state.selectedDepartmentsForNetPromoter]
        let chk = arr.some(el => el == data)
        if (chk) {
            arr = arr.filter(el => el != data)
        }
        else {
            arr = [...arr, data]
        }
        console.log("ARR", data, this.state.selectedDepartmentsForNetPromoter, arr)
        this.setState({
            selectedDepartmentsForNetPromoter: arr
        })
        // this.getDistributionRatingData(
        //     this.state.startDateForDistributionRating,
        //     this.state.endDateForDistributionRating,
        //     this.state.selectedHospitalForDistributionRating,
        //     arr
        // );
        this.getNPS_MeterData(
            this.state.startDateForNPS_Data,
            this.state.endDateForNPS_Data,
            this.state.selectedHospitalForNPS_Data,
            arr
        );
    }
    DataChangeReport = async (e) => {
        e.preventDefault();
        this.getReportLogs(this.state.startDateForReportLogs, this.state.endDateForReportLogs);
    }
    async multiselectDeparmentForWordCloud(data) {
        let arr = [...this.state.selectedDepartmentsForWordCloud]
        let chk = arr.some(el => el == data)
        if (chk) {
            arr = arr.filter(el => el != data)
        }
        else {
            arr = [...arr, data]
        }
        console.log("ARR", data, this.state.selectedDepartmentsForWordCloud, arr)
        this.setState({
            selectedDepartmentsForWordCloud: arr
        })
        this.getWordData(
            this.state.startDateForWordCloud,
            this.state.endDateForWordCloud,
            this.state.selectedHospitalForWordCloud,
            arr,
            this.state.selectedLanguageForWordCloud
        );


    }
    async onSelectDepartmentWordCloud(data) {
        if (this.state.selectedDepartmentForWordCloud === data) {
            this.setState({ selectedDepartmentForWordCloud: '' });
            setTimeout(() => {
                document.querySelectorAll(".notSelectedPill input").forEach(ele => {
                    ele.checked = false;
                })
            }, 100);
            this.getWordData(
                this.state.startDateForWordCloud,
                this.state.endDateForWordCloud,
                this.state.selectedHospitalForWordCloud,
                '',
                this.state.selectedLanguageForWordCloud
            );
            return;
        }
        setTimeout(() => {
            document.querySelectorAll(".notSelectedPill input").forEach(ele => {
                ele.checked = false;
            })
        }, 100);
        console.log(data);
        this.setState({ selectedDepartmentForWordCloud: data });
        this.getWordData(
            this.state.startDateForWordCloud,
            this.state.endDateForWordCloud,
            this.state.selectedHospitalForWordCloud,
            data,
            this.state.selectedLanguageForWordCloud
        );
    }

    handlerNPS_DateChanger(type, event) {
        console.log(type, event);
        if (type === "endDate") {
            this.setState({ endDateForNPS_Data: event });
        }
        if (type === "startDate") {
            this.setState({ startDateForNPS_Data: event });
        }
    }
    handlerWordCloud_DateChanger(type, event) {
        console.log(type, event);
        if (type === "endDate") {
            this.setState({ endDateForWordCloud: event });
        }
        if (type === "startDate") {
            this.setState({ startDateForWordCloud: event });
        }
    }

    handleSubmitNPS_DateChanged = (event) => {
        event.preventDefault();
        this.getNPS_MeterData(
            this.state.startDateForNPS_Data,
            this.state.endDateForNPS_Data,
            this.state.selectedHospitalForNPS_Data,
            this.state.selectedDepartmentForNPS_Data
        );
    };

    handleSubmitWordCloudChanged = (event) => {
        event.preventDefault();
        this.getWordData(
            this.state.startDateForWordCloud,
            this.state.endDateForWordCloud,
            this.state.selectedHospitalForWordCloud,
            this.state.selectedDepartmentForWordCloud,
            this.state.selectedLanguageForWordCloud
        );
    }
    finalLineGraph(chartDataValue) {

        return <C3Chart
            data={this.state.chartDataValue}
            size={{
                height: 340,

            }}
            transition={{
                duration: 1000
            }}

            axis={this.getAxisLine()}

            padding={{
                bottom: 0,
                top: 0,
            }}
        />

        // }


    }



    componentWillReceiveProps() {

        this.finalLineGraph();
    }
    componentDidUpdate() {
        this.finalLineGraph();
    }


    getDataSingleBarHorizontal() {
        // console.log('this.state.notificationAgentXAxix',this.state.notificationAgentData)
        if (this.state.notificationAgentXAxix.length > 0) {

            let data = {
                bindto: '#graph3',
                x: 'x',
                columns: [
                    ['x', ...this.state.notificationAgentData],
                    ['Average Rating', ...this.state.notificationAgentXAxix],

                ],
                type: 'bar',
                colors: {
                    'Average Rating': '#f4c430',

                },

            }

            return data
        } else {
            let data = {
                bindto: '#graph3',
                x: 'x',
                columns: [
                    ['x', null],
                    ['Average Rating', null],
                ],
                type: 'bar',

            }
            return data
        }


    }
    handleSubmitSingleBarHorizontal = async event => {
        event.preventDefault();

        this.callNotificationServiceAgent(this.state.filterStartDateSingleBarHorizontal, this.state.filterEndDateSingleBarHorizontal, 'agent')
        this.getDataSingleBarHorizontal()
    }
    handleChangeBarHorizontal(type, date) {
        console.log('datedate', date)
        // debugger
        let changeDate = new Date(date).toLocaleDateString('en-US')
        // console.log('datedate---',changeDate)

        // this.setState({graphStatus:false})
        this.setState({
            [type]: date
        });
        if (type === 'startDateSingleBarHorizontal') {
            this.setState({ filterStartDateSingleBarHorizontal: changeDate })
        } else {
            this.setState({ filterEndDateSingleBarHorizontal: changeDate })
        }

    }
    render() {

        const { dashboardData } = this.state
        return (
            <SiteWrapperSuperAdmin>
                <Page.Content>

                    <div className="row">
                        <Card.Title className="col col-6 col-sm-6 col-lg-10">Analytics</Card.Title>

                        <div className="Download-btn1 pull-right col col-6 col-sm-6 col-lg-2 text-right">
                            {/* <div style={{ height: 400, width: 600 }}>
      <ReactWordcloud words={words} />
    </div> */}
                            {/* <Link
                              to="#"
                              title={changeLanguage(this.props.siteLanguage, 'click_to_download_chat')}
                              onClick={(e) => this.openDownloadPopup(e, client._id)}>
                              <Icon prefix="fa" name="download"></Icon>
                            </Link> */}

                            <Button color="primary btn "
                                className=" aligncentre"
                                type="button"
                                onClick={(e) => this.openDownloadPopup()}
                            >Download</Button>
                        </div>
                    </div>



                    <div className="clearfix"></div>

                    <Grid.Row>


                        <div className="box1_0">
                            <div className="card card101">
                                <div className="card-body  text-center">

                                    <StatsCard layout={1} movement={dashboardData.allUsersPercentageChange} total={dashboardData.todayUsers} label="Total Users" />


                                </div>
                            </div>
                        </div>


                        <div className="box1_0">
                            <div className="card card101">
                                <div className="card-body  text-center">

                                    <StatsCard layout={1}
                                        // movement={dashboardData.todayUniqueUsers}
                                        movement={dashboardData.uniqueUsersPercentageChange}
                                        total={dashboardData.todayUniqueUsers}
                                        label="Total Unique Users"
                                    />


                                </div>
                            </div>
                        </div>



                        <div className="box1_0">
                            <div className="card card101">
                                <div className="card-body p-3 text-center">

                                    <StatsCard layout={1} movement={dashboardData.completedFlowPercentageChange} total={dashboardData.todayCompletedFlow} label="Completed Flows" />


                                </div>
                            </div>
                        </div>


                        <div className="box1_0">
                            <div className="card card101">
                                <div className="card-body p-3 text-center">

                                    <StatsCard layout={1} movement={dashboardData.inCompletedFlowPercentageChange} total={dashboardData.todayInCompletedFlow} label="Incomplete Flows" />


                                </div>
                            </div>
                        </div>


                        <div className="box1_01">
                            <div className="card card101">
                                <div className="card-body p-3 text-center">

                                    <StatsCard layout={1}
                                        // movement={dashboardData.sessionTimePercentageChange} 
                                        total={`${this.state.avgCount.toFixed(2)} %`} label="CSAT" />


                                </div>
                            </div>
                        </div>

                        {/* <div className="col col-sm-6">  
                        <Card>
                            <Card.Header>
                                <Card.Title>Analytics</Card.Title>

                            </Card.Header>
                            <StatsCard layout={1} movement={-3} total="17" label="Closed Today" />
                            <StatsCard layout={1} movement={+4} total="17" label="Closed Today" />

                        </Card>

                        </div> */}

                        <div className="box-al">
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col col-sm-5 ">
                                        <label className="form-label">From Date</label>
                                        <DatePicker className="form-control"
                                            selected={this.state.startDate}
                                            onChange={(e) => this.handleChange('startDate', e)}
                                            maxDate={this.state.endDate}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                        />
                                    </div>

                                    <div className="col col-6 col-sm-5 ">
                                        <label className="form-label">To Date</label>
                                        <DatePicker className="form-control"
                                            selected={this.state.endDate}
                                            onChange={(e) => this.handleChange('endDate', e)}
                                            maxDate={new Date()}
                                            minDate={this.state.startDate}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                        />
                                    </div>
                                    <div className="col col-6 col-sm-2 "><br />
                                        <Button color="primary   sub1" type="submit">Submit</Button>
                                    </div>
                                </div>

                            </form>
                        </div>







                        <div className="col col-12 col-sm-12 col-lg-12">
                            <div className="card card101">
                                <div className="row">
                                    <div class=" pull-left col col-12 col-sm-12 col-lg-7 "><h3 class="card-title">Traffic Analysis</h3></div>


                                    <div className=" col col-12 col-sm-12 col-lg-5">
                                        {
                                            this.state.allContextData &&
                                            this.state.allContextData.length > 0 &&
                                            <Form.Group label="Service" className="pull-right">
                                                <Form.SelectGroup
                                                    canSelectMultiple
                                                    pills
                                                >
                                                    {
                                                        this.state.allContextData.map((val, index) => {
                                                            return (
                                                                <Form.SelectGroupItem
                                                                    label={val.value}
                                                                    name={val.key}
                                                                    value="HTML"
                                                                    onClick={(e) => this.multiselect(val.key)}
                                                                />
                                                            )
                                                        })
                                                    }


                                                </Form.SelectGroup>
                                            </Form.Group>
                                        }

                                    </div>
                                </div>

                                <div className="card-body p-3 text-center">


                                    {

                                        this.finalLineGraph()
                                    }



                                </div>


                                <div>
                                    <div className="sortvalue drop-1">
                                        <select className="form-control custom-select auto1"
                                            onChange={(e) => this.sortGraph(e.target.value)} value={this.state.sortValue}>
                                            <option value="byDay">
                                                By Day
                                            </option>
                                            <option value="byWeek">
                                                By Week
                                            </option>
                                            <option value="byMonth">
                                                By Month
                                            </option>

                                        </select>
                                    </div>
                                </div>




                            </div>
                        </div>

                        <div className="box-al mar-40">
                            <form onSubmit={this.handleSubmitBar}>


                                <div className="row">

                                    <div className="col col-6 col-sm-4 col-lg-5">
                                        <label className="form-label">From Date</label>
                                        <DatePicker className="form-control"
                                            selected={this.state.startDateBar}
                                            onChange={(e) => this.handleChangeBar('startDateBar', e)}
                                            maxDate={this.state.endDateBar}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                        />
                                    </div>

                                    <div className="col col-6 col-sm-4 col-lg-5">
                                        <label className="form-label">To Date</label>
                                        <DatePicker className="form-control"
                                            selected={this.state.endDateBar}
                                            onChange={(e) => this.handleChangeBar('endDateBar', e)}
                                            maxDate={new Date()}
                                            minDate={this.state.startDateBar}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                        />
                                    </div>

                                    <div className="col col-6 col-sm-4 col-lg-2"><br />
                                        <Button color="primary   sub1" type="submit">Submit</Button>
                                    </div>
                                </div>
                            </form>
                        </div>



                        <div className="col col-12 col-sm-12 col-lg-12">
                            <div className="card card101">
                                <div class="row">
                                    <div class=" pull-left col col-12 col-sm-12 col-lg-12"><h3 class="card-title">Service Breakdown</h3></div>
                                </div>

                                <div className="card-body p-3 text-center">
                                    <C3Chart
                                        data={this.getDataBar()}

                                        size={{
                                            height: 440,

                                        }}
                                        bar={
                                            {
                                                width: 50
                                            }
                                        }
                                        axis={this.getAxisLineBar()}
                                        padding={{
                                            bottom: 0,
                                            top: 0,
                                        }}
                                    />
                                </div>
                                {/* 
                                <div>
                                    <div className="sortvalue drop-1">
                                        <select className="form-control custom-select auto1"
                                            onChange={(e) => this.sortGraphBar(e)} value={this.state.sortValueBar}>
                                            <option value="byDay">
                                                By Day
                                    </option>
                                            <option value="byWeek">
                                                By Week
                                    </option>
                                            <option value="byMonth">
                                                By Month
                                    </option>

                                        </select>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                        <div className="box-al mar-40">
                            <form onSubmit={this.handleSubmitSingleBar}>
                                <div className="row">
                                    <div className="col col-6 col-sm-4 col-lg-5">
                                        <label className="form-label">From Date</label>
                                        <DatePicker className="form-control"
                                            selected={this.state.startDateSingleBar}
                                            onChange={(e) => this.handleChangeSingleBar('startDateSingleBar', e)}
                                            maxDate={this.state.endDateSingleBar}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                        />
                                    </div>

                                    <div className="col col-6 col-sm-4 col-lg-5">
                                        <label className="form-label">To Date</label>
                                        <DatePicker className="form-control"
                                            selected={this.state.endDateSingleBar}
                                            onChange={(e) => this.handleChangeSingleBar('endDateSingleBar', e)}
                                            maxDate={new Date()}
                                            minDate={this.state.startDateSingleBar}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                        />
                                    </div>

                                    <div className="col col-6 col-sm-4 col-lg-2"><br />
                                        <Button color="primary   sub1" type="submit">Submit</Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col col-12 col-sm-12 col-lg-12">
                            <div className="box-3rd card card101">
                                <div className="bar-context row">
                                    <div class=" pull-left col col-12 col-sm-12 col-lg-7"><h3 class="card-title"> AI Flow Experience</h3></div>
                                    <div className=" col col-12 col-sm-12 col-lg-5">
                                        {
                                            this.state.notificationContextData &&
                                            this.state.notificationContextData.length > 0 &&
                                            <Form.Group label="Service" className="pull-right">
                                                <Form.SelectGroup
                                                    canSelectMultiple
                                                    pills
                                                >
                                                    {
                                                        this.state.notificationContextData.map((val, index) => {
                                                            return (
                                                                <Form.SelectGroupItem
                                                                    label={val.value}
                                                                    name={val.key}
                                                                    value="HTML"
                                                                    onClick={(e) => this.multiselectSingle(val.key)}
                                                                />
                                                            )
                                                        })
                                                    }


                                                </Form.SelectGroup>
                                            </Form.Group>
                                        }

                                    </div>
                                </div>
                                <div className="card-body p-3 text-center">
                                    <C3Chart
                                        data={this.getDataSingleBar()}

                                        size={{
                                            height: 440,

                                        }}
                                        bar={
                                            {
                                                width: 50
                                            }
                                        }
                                        axis={this.getAxisLineBar()}
                                        padding={{
                                            bottom: 0,
                                            top: 0,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="box-al mar-40">
                            <form onSubmit={this.handleSubmitSingleBarHorizontal}>


                                <div className="row">

                                    <div className="col col-6 col-sm-4 col-lg-5">
                                        <label className="form-label">From Date</label>
                                        <DatePicker className="form-control"
                                            selected={this.state.startDateSingleBarHorizontal}
                                            onChange={(e) => this.handleChangeBarHorizontal('startDateSingleBarHorizontal', e)}
                                            maxDate={this.state.endDateSingleBarHorizontal}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                        />
                                    </div>

                                    <div className="col col-6 col-sm-4 col-lg-5">
                                        <label className="form-label">To Date</label>
                                        <DatePicker className="form-control"
                                            selected={this.state.endDateSingleBarHorizontal}
                                            onChange={(e) => this.handleChangeBarHorizontal('endDateSingleBarHorizontal', e)}
                                            maxDate={new Date()}
                                            minDate={this.state.startDateSingleBarHorizontal}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                        />
                                    </div>

                                    <div className="col col-6 col-sm-4 col-lg-2"><br />
                                        <Button color="primary   sub1" type="submit">Submit</Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col col-12 col-sm-12 col-lg-12">
                            <div className="box-3rd card card101">
                                <div className="bar-context row">
                                    <div class=" pull-left col col-12 col-sm-12 col-lg-7"><h3 class="card-title">Average Agent Rating</h3></div>
                                </div>
                                <div className="card-body p-3 text-center">
                                    <C3Chart

                                        // bindto ={{'#uv-div'}}
                                        size={{
                                            height: 500
                                        }}
                                        bar={{
                                            width: 50
                                        }}
                                        padding={{
                                            left: 100
                                        }}
                                        color={{
                                            pattern: ['#8a203e', '#ACB6DD']
                                        }}
                                        // data= {{
                                        //     x: 'x',
                                        //     columns:
                                        //         [
                                        //       ['x', 'Category1', 'Category2'],
                                        //       ['value', 300, 400]
                                        //       ],

                                        //     type: 'bar',


                                        // }}
                                        data={this.getDataSingleBarHorizontal()}
                                        axis={{
                                            rotated: true,
                                            x: {
                                                type: 'category'
                                            }
                                        }}
                                        tooltip={{
                                            grouped: false
                                        }}
                                        legend={{
                                            show: false
                                        }}

                                    />

                                </div>
                                <div>

                                </div>
                            </div>
                            {/* <FeedBackAnalytics /> */}
                        </div>
                        {/* NPS Data */}
                        <div className="box-al mar-40 spaceBetweenContainer">
                            {/* <form onSubmit={this.handleSubmitNPS_DateChanged} style={{ width: "auto" }}>
                                <div className="row rowWithNowrap">
                                    <div className="col ">
                                        <label className="form-label">From Date</label>
                                        <DatePicker
                                            className="form-control"
                                            selected={this.state.startDateForNPS_Data}
                                            onChange={(e) =>
                                                this.handlerNPS_DateChanger("startDate", e)
                                            }
                                            maxDate={this.state.endDateForNPS_Data}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                            maxDate={new Date()}
                                        />
                                    </div>

                                    <div className="col ">
                                        <label className="form-label">To Date</label>
                                        <DatePicker
                                            className="form-control"
                                            selected={this.state.endDateForNPS_Data}
                                            onChange={(e) =>
                                                this.handlerNPS_DateChanger("endDate", e)
                                            }
                                            maxDate={new Date()}
                                            minDate={this.state.startDateForNPS_Data}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                        />
                                    </div>

                                    <div className="col col-6 col-sm-4 col-lg-2">
                                        <br />
                                        <Button color="primary   sub1" type="submit">
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </form> */}

                            {/* <div style={{ marginRight: '13px' }}>
                                <label className="form-label">Hospital</label>
                                <select className="form-control custom-select auto1" style={{ width: 'auto' }} onChange={(e) => this.onSelectHospital("nps", e.target.value)} value={this.state.selectedHospitalForNPS_Data}>
                                    <option value="all">all Hospital</option>
                                    {
                                        this.state.hospitalsList.map(hospital => (
                                            <option value={hospital}>{hospital}</option>
                                        ))
                                    }
                                </select>
                            </div> */}
                        </div>

                        {/* <div className="col col-12 col-sm-12 col-lg-12">
                            <div className="box-3rd card card101">
                                <div className="bar-context row">
                                    <div class=" pull-left col col-12 col-sm-12 col-lg-7">
                                        <h3 class="card-title">Net Promoter Score</h3>
                                    </div>
                                    <div className=" col col-12 col-sm-12 col-lg-5">
                                        {this.state.departmentsList && this.state.departmentsList.length > 0 && (
                                            <Form.Group label="Departments" className="pull-right">
                                                <Form.SelectGroup canSelectMultiple={true} pills>
                                                    {this.state.departmentsList.map((val, index) => {
                                                        return (
                                                            <Form.SelectGroupItem
                                                                label={val}
                                                                name={val}
                                                                value="HTML"
                                                                onClick={(e) => this.multiselectDeparmentForNetPromoter(val)}
                                                            />
                                                        );
                                                    })}
                                                </Form.SelectGroup>
                                            </Form.Group>
                                        )}
                                    </div>

                                </div>
                                <div className="card-body p-3 text-center" style={{ display: "flex" }}>
                                    <div >
                                        <C3Chart
                                            size={{
                                                height: 250,
                                                width: 500,
                                            }}
                                            bar={{
                                                width: 50,
                                            }}
                                            padding={{
                                                left: 0,
                                                right: 250,
                                            }}
                                            data={this.state.NPS_MeterData}
                                        />
                                    </div>
                                    <div>
                                        <C3Chart
                                            size={{
                                                height: 300,
                                                width: 600
                                            }}
                                            bar={{
                                                width: 50,
                                            }}
                                            padding={{
                                                left: 60,
                                            }}
                                            data={this.state.NPS_BarData}
                                            axis={{
                                                rotated: true,
                                                x: {
                                                    type: "category",
                                                    categories: ["Promoters", "Passives", "Detractors"],
                                                },
                                            }}
                                            tooltip={{
                                                contents: this.NPS_BarData_tooltips
                                            }}
                                            legend={{
                                                show: false,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        </div> */}

                        {/* Word Cloud */}
                        {/* <div className="box-al mar-40 spaceBetweenContainer">
                            <form onSubmit={this.handleSubmitWordCloudChanged} style={{ width: "auto" }}>
                                <div className="row rowWithNowrap">
                                    <div className="col">
                                        <label className="form-label">From Date</label>
                                        <DatePicker
                                            className="form-control"
                                            selected={this.state.startDateForWordCloud}
                                            onChange={(e) =>
                                                this.handlerWordCloud_DateChanger("startDate", e)
                                            }
                                            maxDate={this.state.endDateForWordCloud}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                            maxDate={new Date()}
                                        />
                                    </div>

                                    <div className="col">
                                        <label className="form-label">To Date</label>
                                        <DatePicker
                                            className="form-control"
                                            selected={this.state.endDateForWordCloud}
                                            onChange={(e) =>
                                                this.handlerWordCloud_DateChanger("endDate", e)
                                            }
                                            maxDate={new Date()}
                                            minDate={this.state.startDateForWordCloud}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                        />
                                    </div>

                                    <div className="col">
                                        <br />
                                        <Button color="primary   sub1" type="submit">
                                            Submit
                                        </Button>
                                    </div>
                                </div>

                            </form>
                            <div style={{ display: "flex", marginRight: '13px' }}>
                            </div>
                        </div>
                        <div className="col col-12 col-sm-12 col-lg-12">
                            <div className="box-3rd card card101">
                                <div className="bar-context row">
                                    <div class=" pull-left col col-12 col-sm-12 col-lg-7">
                                        <h3 class="card-title">Feedback Word Cloud</h3>
                                    </div>

                                    <div className=" col col-12 col-sm-12 col-lg-5">
                                        {this.state.departmentsList && this.state.departmentsList.length > 0 && (
                                            <Form.Group label="Departments" className="pull-right">
                                                <Form.SelectGroup canSelectMultiple={true} pills>
                                                    {this.state.departmentsList.map((val, index) => {
                                                        return (
                                                            <Form.SelectGroupItem
                                                                label={val}
                                                                name={val}
                                                                value="HTML"
                                                                onClick={(e) => this.multiselectDeparmentForWordCloud(val)}
                                                            />
                                                        );
                                                    })}
                                                </Form.SelectGroup>
                                            </Form.Group>
                                        )}
                                    </div>

                                </div>
                                <div className="card-body p-3 text-center">
                                    <ReactWordcloud
                                        words={this.state.words}
                                        size={this.state.WordCloudSize}
                                        options={{
                                            rotations: 2,
                                            rotationAngles: [-90, 0],
                                            fontSizes: [18, 100],
                                        }}
                                    />
                                </div>
                            </div>
                        </div> */}
                        {/* reportLogs  */}
                        <div className="box-al mar-40">
                            <form onSubmit={this.DataChangeReport}>
                                <div className="row">
                                    <div className="col col-6 col-sm-4 col-lg-5">
                                        <label className="form-label">From Date</label>
                                        <DatePicker
                                            className="form-control"
                                            selected={this.state.startDateForReportLogs}
                                            onChange={(e) => this.handlerAllChatDateChanger("startDateForReportLogs", e)}
                                            maxDate={this.state.endDateForReportLogs}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                        />
                                    </div>

                                    <div className="col col-6 col-sm-4 col-lg-5">
                                        <label className="form-label">To Date</label>
                                        <DatePicker
                                            className="form-control"
                                            selected={this.state.endDateForReportLogs}
                                            onChange={(e) => this.handlerAllChatDateChanger("endDateForReportLogs", e)}
                                            maxDate={new Date()}
                                            minDate={this.state.startDateForReportLogs}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                        />
                                    </div>

                                    <div className="col col-6 col-sm-4 col-lg-2">
                                        <br />
                                        <Button color="primary   sub1" type="submit" onClick={() => this.getReportLogs(this.state.startDateForReportLogs, this.state.endDateForReportLogs)}>
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col col-12 col-sm-12 col-lg-12">
                            <div className="box-3rd card card101">
                                <div className="bar-context row">
                                    <div className=" pull-left col col-12 col-sm-12 col-lg-7">
                                        <h3 className="card-title"> Report Logs </h3>
                                    </div>
                                </div>
                                <div className="card-body p-3 text-center">
                                    <C3Chart
                                        data={this.state.reportLogs}
                                        // axis= {{
                                        //     rotated: true,
                                        //     x: {
                                        //         type: 'category'
                                        //     }
                                        // }}
                                        // tooltip= {{
                                        //     grouped: false
                                        // }}
                                        // adminId = res.locals.userId,
                                        legend={{
                                            show: true
                                        }}
                                        donut={{
                                            title: "Report Logs"
                                        }}
                                        tooltip={{
                                            contents: this.tooltip_contentsForReportLogs
                                        }}
                                        colors={{
                                            Success: '#ff0000',
                                            Failure: '#00ff00',
                                        }}
                                    />

                                </div>
                                <div>
                                </div>
                            </div>
                        </div>


                        {/* payment  */}



                        <PaymentLogs />


                    </Grid.Row>
                    <DownloadPopup
                        openInfoPopup={this.state.openDownloadPopup}
                        onChange={this.closeDownloadPopup}
                    ></DownloadPopup>
                </Page.Content>
            </SiteWrapperSuperAdmin>
        )
    }

}


const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        activeBot: state.auth.activeBot
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logOut: () => {
            dispatch(authActions.authLogout())
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Analytic));
