// @flow

import React from "react";
import {
    Form,
    Grid,
    Card,
    Button,
    Icon

} from "tabler-react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SiteWrapperSuperAdmin from "../SiteWrapper.superadmin.react";
import { validate } from '../../validate';
import clientService from "../../services/client";
import Buttonloader from '../../common/Loader/Buttonloader'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { changeLanguage } from '../../../src/language/index';

const validationRules = [
    {
        field: 'oldPassword',
        validations: ['required', 'min:3', 'max:15'],
        name: 'Old Password'
    },
    {
        field: 'newPassword',
        validations: ['required', 'min:3', 'max:15'],
        name: 'New Password'
    },
    {
        field: 'confirmPassword',
        validations: ['required', 'min:3', 'max:100'],
        name: 'Confirm Password'
    },
]

class UpdateData extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
            },
            message: {
                style: 'success',
                text: ''
            },
            isLoading: false,
            errors: {}
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleNotification = this.handleNotification.bind(this);
        this.setLoadingToggle = this.setLoadingToggle.bind(this);
    }

    handleChange = (e, field) => {
        const { fields } = this.state, val = e.target.value;
        fields[field] = val;
        this.setState({ fields });
        //this.validate();
    }

    setLoadingToggle(value) {
        this.setState({ isLoading: value })
    }

    // validate
    validate() {
        const { fields } = this.state;
        const { isValid, errors } = validate(fields, validationRules);
        if ((!fields['confirmPassword'] == '') &&
            (fields['confirmPassword'] != fields['newPassword'])) {
            const { isValid } = false;
            errors['confirmPassword'] = 'Confirm Password didn\'t match'
        }
        this.setState({ errors });
        return isValid;
    }

    //form submition
    handleSubmit = async event => {
        event.preventDefault();
        if (this.validate()) {
            this.setLoadingToggle(true);

            const data = {
                oldPassword: this.state.fields.oldPassword,
                newPassword: this.state.fields.newPassword
            }
            clientService.changePassword(data)
                .then(response => {
                    toast.success("Password Changed successfully.")
                    setTimeout(() => {
                        this.props.history.goBack()
                    }, 3000)

                })
                .catch(error => {
                    this.setLoadingToggle(false);
                    this.setState(() => ({ message: { text: error.response.data.message, style: 'danger' } }));
                    setTimeout(() => {
                        this.handleNotification();
                    }, 3000)
                })
        }
        else {
            console.log('eeeeeeee');
        }
    }


    handleSyncDoctor = async () => {
        try {
            this.setLoadingToggle(true);
            const { data: res } = await clientService.syncDoctors();
            if (res) {
                toast.success("Doctors Sync Successfull")
                this.setLoadingToggle(false);

            }
        } catch (error) {
            console.error(error)
            this.setLoadingToggle(false);
        }
    }

    handleSyncSpecialization = async () => {
        try {
            this.setLoadingToggle(true);

            const { data: res } = await clientService.syncSpecialization();
            if (res) {
                toast.success("Specialization Sync Successfull")
                this.setLoadingToggle(true);

            }
        } catch (error) {
            console.error(error)
            this.setLoadingToggle(false);

        }
    }

    componentDidMount() {
        document.title = "Voiceoc | Update Data"
    }

    handleNotification() {
        this.setState(() => ({ message: { text: '', style: '' } }));
    }

    render() {
        const { message } = this.state;

        return (
            <SiteWrapperSuperAdmin>
                <Grid.Col lg={5} className="mx-auto ">
                    <Card>
                        <Card.Header>
                            <Card.Title>{changeLanguage(this.props.siteLanguage, 'updateData')}</Card.Title>
                        </Card.Header>
                        <Card.Body>

                            <form >
                                <Grid.Row>

                                    <Grid.Col lg={6}>

                                        <label>Doctor</label>
                                        {!this.state.isLoading ?
                                            <Button color="primary btn-block" type="button" onClick={() => this.handleSyncDoctor()}>{changeLanguage(this.props.siteLanguage, 'updateDoctor')}</Button>
                                            :
                                            <Buttonloader loading={this.state.isLoading}></Buttonloader>
                                        }

                                    </Grid.Col>
                                    <Grid.Col lg={6}>
                                        <label>Specialization</label>
                                        {!this.state.isLoading ?
                                            <Button color="primary btn-block" type="button" onClick={() => this.handleSyncSpecialization()} >{changeLanguage(this.props.siteLanguage, 'updateSpecialization')}</Button>
                                            :
                                            <Buttonloader loading={this.state.isLoading}></Buttonloader>
                                        }
                                    </Grid.Col>
                                </Grid.Row>


                            </form>
                        </Card.Body>
                    </Card>
                </Grid.Col>
                <ToastContainer autoClose={5000} position={'bottom-right'} />
            </SiteWrapperSuperAdmin >
        )
    }

}
const mapStateToProps = (state) => {
    return {
        siteLanguage: state.auth.siteLanguage
    }
}


// export default ChangePassword;
export default withRouter(connect(mapStateToProps)(UpdateData));

