import moment from "moment";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, Form, Button, Table } from "tabler-react";
import { getUploadNotifications } from "../services/client";
// import { getoptinUpload } from "../../services/client";

function ViewUploadNotification({ refreshData }) {
  const [optinData, setOptinData] = useState([]);

  const getData = async () => {
    try {
      let res = await getUploadNotifications();
      if (res.data && res.data.length) setOptinData(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(
    () => {
      getData();
    },
    [refreshData]
  );

  return (
    <Card>
      <Card.Header>
        <Card.Title>CSV History</Card.Title>
      </Card.Header>
      <Card.Body>
        <Table cards={true} striped={true} responsive={true} className="table-vcenter ">
          <Table.Header>
            <Table.Row>
              <Table.ColHeader>Sno.</Table.ColHeader>
              <Table.ColHeader>Status</Table.ColHeader>
              <Table.ColHeader>File Name</Table.ColHeader>
              <Table.ColHeader>Download File</Table.ColHeader>
              <Table.ColHeader>Date</Table.ColHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {optinData &&
              optinData.map((obj, index) => (
                <Table.Row key={index}>
                  <Table.Col>{index + 1}</Table.Col>
                  <Table.Col className="text-nowrap">{obj.status}</Table.Col>
                  <Table.Col className="text-nowrap">{obj.uploadedFileName}</Table.Col>
                  <Table.Col className="text-nowrap">
                    {obj.status == "COMPLETED" && (
                      <a href={obj.processedUri} target="_blank">
                        Download
                      </a>
                    )}
                  </Table.Col>
                  <Table.Col className="text-nowrap">{obj.createdAt && moment(obj.createdAt).format("DD/MM/YY h:mm a")}</Table.Col>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  );
}

export default withRouter(ViewUploadNotification);
