// @flow

import React from "react";
import {
  Icon,
  Grid,
  Card,
  Table,
  Form,
  Button,
  Page
} from "tabler-react";
import { Link } from 'react-router-dom'
// import SiteWrapperSuperAdmin from "../../SiteWrapper.superadmin.react";
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authActions from "../../../redux/auth/action";
import { getCategory, deleteCategory } from "../../../services/campaign";
import { changeLanguage } from '../../../language/index';
import SiteWrapperCamapignSuperAdmin from "../SiteWrapper.campaign.superadmin.react";

import socketIOClient from 'socket.io-client';
var { jwtToken } = localStorage;
// var ENDPOINT = "https://waagentportal.rpsapi.in";
var ENDPOINT = process.env.REACT_APP_baseUrl;
// import Loader from '../../common/Loader/loader'

const paginationLimit = 10

class CategoryList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: this.props.match.params.page,
      categoryArr: [],
      totalRecords: 0,
      nextPage: false,
      prevoiusPage: false,
      search: '',
      isLoading: true,
      // openDownloadPopup: false,
    }
    this.search = this.search.bind(this);
  }


  componentDidMount() {
    if (!this.props.isLoggedIn) {
      this.props.history.push('/login');
    }
    document.title = 'Voiceoc | Category List';
    this.callService(this.state.currentPage);
  }

  //handle change
  handleChange = (e, field) => {
    this.setState({ search: e.target.value });
  }

  setLoadingToggle(value) {
    this.setState({ isLoading: value })
  }

  //delete admin
  removeCategory = (event, id) => {
    if (window.confirm('Are you sure to delete this category?')) {

      event.preventDefault();
      const { currentPage } = this.state;
      const { search } = this.state

      this.setLoadingToggle(true);

      deleteCategory(id)
        .then(response => {
          toast.success("Category Deleted Successfully.")
          this.callService(currentPage, search);
        })
        .catch(error => {
          if (error.response && error.response.data.message)
            toast.error(error.response.data.message)
          else if (error.message)
            toast.error(error.message)
          else
            toast.error(error)
          this.setLoadingToggle(false);
        })
    }
  }


  callService(page, search = false) {
    getCategory(page, search)
      .then(res => {
        console.info("res", res)
        this.setState(() => ({
          categoryArr: res.data.result,
          nextPage: res.data.next,
          totalRecords: res.data.total,
          prevoiusPage: this.state.currentPage > 1 ? true : false,
        }));
      })
  }

  pagination(e, page) {
    this.callService(page);
    this.props.history.push("/admin/campaigntabs/category/" + page);
  }

  search(clear = false) {
    if (clear) {
      this.setState({
        search: ''
      });
    }
    setTimeout(() => {
      this.callService(1, this.state.search);
    }, 100);

  }

  handleViewClient(path, client) {
    this.props.adminLoginAsClient(client);
  }

  getPagination() {
    let startRecord = this.props.match.params.page == 1 ? this.props.match.params.page : ((paginationLimit * (this.props.match.params.page - 1)) + 1)
    let endRecord = (parseInt(startRecord) + parseInt(this.state.categoryArr.length) - 1);
    if (this.state.totalRecords)
      return startRecord + '-' + endRecord + ' of ' + this.state.totalRecords;
    else
      return startRecord + '-' + endRecord;
  }

  render() {
    const pageNumber = this.props.match.params.page;
    const finalNumeber = (pageNumber - 1) * paginationLimit;
    // return(<h1>WAIT</h1>)
    return (

      <SiteWrapperCamapignSuperAdmin>
        <Page.Content>

          <Grid.Row>
            <Grid.Col lg={12}>
              <Card>
                <Card.Header>
                  <Card.Title> {changeLanguage(this.props.siteLanguage, 'category')}</Card.Title>
                  <Form.Group label="" className="ml-auto">
                    <Form.InputGroup className="ml-auto1">
                      <Form.Input placeholder="Search..." className="ml-auto1 "
                        name="search"
                        value={this.state.search}
                        onChange={(e) => this.handleChange(e, 'search')} />
                      <Form.InputGroupAppend>
                        <Button color="primary" className="ml-auto1 btn1a"
                          onClick={(e) => this.search()}>
                          {changeLanguage(this.props.siteLanguage, 'go')}
                        </Button>
                        <Button color="primary" className="ml-auto1 ml-auto10 btn1a"
                          onClick={(e) => this.search(true)}>
                          {changeLanguage(this.props.siteLanguage, 'clear')}
                        </Button>
                      </Form.InputGroupAppend>
                    </Form.InputGroup>
                  </Form.Group>
                </Card.Header>
                <Table
                  cards={true}
                  striped={true}
                  responsive={true}
                  className="table-vcenter table-hover"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>  {changeLanguage(this.props.siteLanguage, 'sl_no')}</Table.ColHeader>
                      <Table.ColHeader >{changeLanguage(this.props.siteLanguage, 'name_en')}</Table.ColHeader>
                      <Table.ColHeader >{changeLanguage(this.props.siteLanguage, 'name_ar')}</Table.ColHeader>
                      <Table.ColHeader className="center">{changeLanguage(this.props.siteLanguage, 'action')}</Table.ColHeader>

                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.categoryArr && this.state.categoryArr.length ?
                      this.state.categoryArr.map((category, index) => (
                        <Table.Row key={index}>
                          <Table.Col>{index + 1 + finalNumeber}</Table.Col>
                          <Table.Col>{category.name_en}</Table.Col>
                          <Table.Col>{category.name_ar}</Table.Col>
                          <Table.Col className="text-nowrap actions chat-w1">
                            <Link to={`/admin/campaigntabs/category/edit/${category._id}`} title={changeLanguage(this.props.siteLanguage, 'click_to_edit')}>
                              <Icon prefix="fa" name="edit"></Icon>
                            </Link>
                            <Link
                              to="#"
                              title={changeLanguage(this.props.siteLanguage, 'click_to_delete')}
                              onClick={(e) => this.removeCategory(e, category._id)}>
                              <Icon prefix="fa" name="trash"></Icon>
                            </Link>
                          </Table.Col>
                        </Table.Row>
                      ))
                      :
                      <Table.Row>
                        <Table.Col colSpan={6} >Sorry! Categories not found.</Table.Col>
                      </Table.Row>
                    }

                  </Table.Body>
                </Table>
                {/* pagination */}
                <div className="form-group" style={{ float: 'right' }}>
                  <label className="form-label">

                  </label>
                  <div className="selectgroup align-to-right paginations">
                    <span>
                      {this.getPagination()}
                    </span>

                    <i
                      className={
                        "fe " +
                        (this.props.match.params.page <= 1 ? "not-active " : "cursorPointer ") +
                        " fe-chevron-left"
                      }
                      onClick={(e) => this.pagination(e, (parseInt(this.props.match.params.page) - 1))}
                      disabled={this.props.match.params.page <= 1}>
                    </i>

                    <i
                      className={
                        "fe " +
                        (!this.state.nextPage ? "not-active " : "cursorPointer ") +
                        " fe-chevron-right"
                      }
                      onClick={(e) => this.pagination(e, (parseInt(this.props.match.params.page) + 1))}
                      disabled={!this.state.nextPage}></i>
                  </div>
                </div>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
        <ToastContainer autoClose={5000} position={'bottom-right'} />
      </SiteWrapperCamapignSuperAdmin>
    )
  }

}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isAuthenticated,
    toast: state.auth.toast,
    siteLanguage: state.auth.siteLanguage
  }
}

function mapDispatchToProps(dispatch) {
  return {
    adminLoginAsClient: (payload) => {
      dispatch(authActions.adminLoginAsClient(payload))
    },
    setActiveBot: (payload) => {
      dispatch(authActions.setActiveBot(payload))
    }
  }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoryList));
// export default Clients;
