// @flow

import React from "react";
import {
  Grid,
  Card,
  Table,
  Page,
  colors,
  Form, Button
} from "tabler-react";
import Select from 'react-select';
import Moment from 'moment';
import "tabler-react/dist/Tabler.css";
import C3Chart from "react-c3js";
import { Link, withRouter, browserHistory } from 'react-router-dom'
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import SiteWrapperSuperAdmin from "./SiteWrapper.superadmin.react";
import authActions from "../redux/auth/action";
import clientService from "../services/client";
import InfoPopup from './InfoPopup';
import { changeLanguage } from '../../src/language/index';
class SuperAdminDashboard extends React.Component {

  constructor(props) {
    super(props)
    this.chatDiv = React.createRef()
    this.state = {
      currentPage: this.props.match.params.page,
      nextPage: false,
      totalRecords: 0,
      selectedAdmin: null,
      clientId: null,
      selectChatUser: null,
      adminOptions: [],
      chatUsers: [],
      messages: [],
      openPopup: false,
      openInfoPopup: false,
      page: 1,
      search: '',
      selectedUser: {},
    }
    this.closeInfoPopup = this.closeInfoPopup.bind(this);
  }

  closeInfoPopup(data) {

    this.setState({
      openInfoPopup: {
        open: false,
        data: false
      }
    })
  }

  openInfoPopup(e, data) {
    e.preventDefault();
    this.setState({
      openInfoPopup: {
        open: true,
        data: data.customerDetail[0].entity,
      }
    })
  }


  componentWillMount() {

  }

  getFilterChatUser() {
    const id = this.props.match.params.userId;
    var chatUser = this.state.chatUsers.filter(function (user) {
      if (user.customerDetail && user.customerDetail.length)
        return user.customerDetail[0]._id === id

      return false
    });
    if (chatUser.length) {
      this.setState({
        selectChatUser: chatUser[0]
      })
    }

    return '';
  }



  componentWillUnmount() {
    this.unlisten();
    //this.detectChnageURl();
  }

  componentDidMount() {


    this.unlisten = this.props.history.listen((location, action) => {
      let url = location.pathname.split('/')
      if (url != undefined && url[3] != undefined) {
        this.setState({
          clientId: url[3]
        })
        this.getFilterChatUser();
        this.getChatUsers(1, this.state.search, url[3])
      }
      this.getAllAdmin()
    });


    document.title = "Voiceoc | Chat";
    //this.detectChnageURl();
    this.getAllAdmin()
    if (this.props.match.params && this.props.match.params.id != undefined) {
      this.setState({
        clientId: this.props.match.params.id
      })
      this.getChatUsers(1, this.state.search, this.props.match.params.id)
    }

  }

  getMessages(page = 1) {
    let chatId = this.state.selectChatUser._id
    console.log("SELECETD ", this.state.selectChatUser)
    if (this.state.selectChatUser && this.state.selectChatUser.customerDetail.length && this.state.selectChatUser.customerDetail[0].mobileNumber) {

      clientService.getAllMessages(this.state.selectChatUser.customerDetail[0].mobileNumber, page)
        .then(res => {
          if (res.statusCode === 200) {
            if (res.data.result && res.data.result.length) {
              var allMessage = (res.data.result.reverse()).concat(this.state.messages);
              this.setState(() => ({
                messages: allMessage,
                page: page
              }));
            }
            if (page === 1) {
              this.setState(() => ({
                messages: res.data.result,
                page: 1
              }));
              this.scrollToBottom();
            }

            console.log('messagesmessages---226', this.state.messages)
            if (page > 1 && res.data.result && res.data.result.length) {
              this.chatDiv.current.scrollTop = 100
            }
          }
        })
        .catch(err => console.error(err))
    }
  }

  scrollToBottom = () => {
    if (this.chatDiv.current) {
      this.chatDiv.current.scrollTop = this.chatDiv.current.scrollHeight;
    }
  };

  getChatUsers(page, search, clientId, sortKey = false, sortType = false) {
    clientService.getChatUsers(page, search, 50, clientId, sortKey, sortType)
      .then(res => {
        if (res.statusCode === 200) {
          this.setState(() => ({
            chatUsers: res.data.result,
            nextPage: res.data.next,
            totalRecords: res.data.total,
            prevoiusPage: this.state.currentPage > 1 ? true : false,
          }));
          this.getFilterChatUser();
          if (this.props.match.params && this.props.match.params.userId != undefined) {
            this.getMessages()
          }
        }
      }).catch(error => {
        console.log(error);
        if (error && error.response && error.response.data && error.response.data.statusCode === 201) {
          this.props.logOut();
          this.props.history.push("/login");
        }

      });
  }

  getAllAdmin(page = 1, search = false) {

    clientService.getClients(page, search, 100)
      .then(res => {
        if (res.statusCode === 200) {
          this.setState(() => ({
            adminOptions: res.data.result,
          }));
        }
        this.detectChnageURl();
      })
  }

  getFilterAdmin(id) {
    var admins = this.state.adminOptions.filter(function (admin) {
      return admin._id === id
    });
    if (admins.length) {
      return admins[0]
    }
    return '';
  }

  detectChnageURl() {
    if (this.props.match.params && this.props.match.params.id != undefined) {
      let label = this.getFilterAdmin(this.props.match.params.id);
      this.setState({
        selectedAdmin: { value: label._id, label: label.name }
      })
    }

    if (this.props.match.params && this.props.match.params.clientId != undefined) {
      this.setState({
        clientId: this.props.match.params.clientId
      })
    }
  }

  //listen client change
  selectAdmin = selectedAdmin => {
    this.props.history.push('/admin/dashboard/' + selectedAdmin.value + '/1');
  }

  formatDate(date) {
    return Moment(date).format('DD/MM/YY h:mm a');
  }

  getPagination() {
    var page = this.props.match.params.page;
    var startRecord = this.props.match.params.page == 1 ? this.props.match.params.page : ((50 * (this.props.match.params.page - 1)) + 1)
    var endRecord = (parseInt(startRecord) + parseInt(this.state.chatUsers.length) - 1);
    return startRecord + '-' + endRecord
    //+' of '+this.state.totalRecords;
  }

  selectUserToChat(e, tmpuser) {

    if (tmpuser.customerDetail && tmpuser.customerDetail.length && tmpuser.customerDetail[0]) {
      let user = tmpuser.customerDetail[0]

      this.setState({
        selectedUser: user
      })
      this.props.history.push('/admin/dashboard/' + this.state.selectedAdmin.value + '/1/' + user._id);
    }
  }

  handleScroll(event) {
    let self = this;
    if (event.target.scrollTop === 0) {
      //console.log('abc',event.target.scrollTop)
      let activePage = this.state.page + parseInt(1);
      this.getMessages(activePage)
    }
  }

  orderByChatList(e) {
    var valueKey = e.target.value.split('_');
    this.getChatUsers(1, this.state.search, this.props.match.params.id, valueKey[1], valueKey[0])
  }

  async handleSearch(event) {

    // console.log('this.props.match.params.clientId',this.state.selectedAdmin.value)
    await this.setState({
      search: event.target.value
    })
    this.getChatUsers(this.props.match.params.page, this.state.search, this.state.selectedAdmin.value, false)
    // this.props.history.push("/admin/all-chat/" + 1);
  }


  render() {
    console.log('siteLanguageNN', this.props.siteLanguage)
    const { messages } = this.state;
    //messages.reverse();
    const { selectChatUser } = this.state;
    const { nextPage } = this.state;
    const { chatUsers } = this.state;
    const { selectedAdmin } = this.state;
    const { clientId } = this.state;
    const { adminOptions } = this.state;
    const adminAll0ptions = adminOptions.reduce((result, { name, _id, status, agentType  }) => [...result, ... status == 'active' && agentType =='chatAgent'  ? [{ label: name, value: _id }] : []], []);
    return (
      <SiteWrapperSuperAdmin>
        <Page.Content>
          <fieldset className="form-fieldset col col-sm-4 mx-auto selectAdmin ">

            <div className="form-group">
              <label className="form-label">{changeLanguage(this.props.siteLanguage, 'select_agent')}<span className="form-required">*</span></label>
              <Select name="category"
                value={selectedAdmin}
                onChange={this.selectAdmin}
                options={adminAll0ptions}
              />

            </div>
          </fieldset>
          {
            selectedAdmin ?
              <div>
                <Grid.Row >
                  <div className="col col-sm-6 ">
                    <div className="page-header">


                      <div className="d-flex col-sm-6">
                        <select className=" custom-select auto1"
                          onChange={(e) => this.orderByChatList(e)}>
                          <option value="asc_createdAt">

                            {changeLanguage(this.props.siteLanguage, 'time_latest_first')}

                          </option>
                          <option value="asc_name">

                            {changeLanguage(this.props.siteLanguage, 'name_a_z')}

                          </option>
                          <option value="desc_name">
                            {changeLanguage(this.props.siteLanguage, 'name_z_a')}

                          </option>


                        </select>

                      </div>

                      <div className="d-flex col-sm-6">

                        <input name="search" className="form-control" type="text"
                          placeholder="Search"
                          value={this.state.search}

                          onChange={(e) => this.handleSearch(e)}
                        />

                      </div>





                    </div>


                  </div>
                </Grid.Row>
                <Grid.Row >

                  <div className="col col-sm-6">
                    <Card>


                      <Table
                        cards={true}
                        striped={true}
                        responsive={true}
                        className="table-vcenter tablebackground"
                      >
                        <Table.Header>
                          <Table.Row>

                            <Table.ColHeader className="capitilize fontSize"> </Table.ColHeader>
                            <Table.ColHeader className="capitilize fontSize aligntd">{changeLanguage(this.props.siteLanguage, 'name')}</Table.ColHeader>
                            <Table.ColHeader className="capitilize fontSize aligntd">{changeLanguage(this.props.siteLanguage, 'date')}</Table.ColHeader>
                            <Table.ColHeader className="capitilize fontSize aligntd">{changeLanguage(this.props.siteLanguage, 'mobile_number')}</Table.ColHeader>

                            <Table.ColHeader className="capitilize fontSize">Context </Table.ColHeader>
                            {/* <Table.ColHeader className="capitilize fontSize">Hospital</Table.ColHeader> */}

                            <Table.ColHeader className="capitilize fontSize">Language </Table.ColHeader>

                            <Table.ColHeader className="capitilize fontSize"></Table.ColHeader>

                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {
                          chatUsers
                          .filter(user=> user.customerDetail && user.customerDetail.length && user.customerDetail[0] && user.customerDetail[0].mobileNumber).length ?
                            chatUsers
                            .filter(user=> user.customerDetail && user.customerDetail.length && user.customerDetail[0] && user.customerDetail[0].mobileNumber)
                            .map((user, childIndex) => (



                              <tr
                                className={
                                  "cursorPointer  " +
                                  (selectChatUser && user.customerId === selectChatUser.customerId ? "highlitedtr" : "") +
                                  ""
                                }
                                key={childIndex}
                                onClick={(e) => this.selectUserToChat(e, user)}
                              >

                                <td className="w-1">
                                  <span className="avataradmin">
                                  </span>
                                </td>

                                <td className="sticky-col aligntd"
                                  >
                                  {user.customerDetail && !!user.customerDetail.length && user.customerDetail[0] && user.customerDetail[0].name}
                                </td>

                                <td className="aligntd">
                                  {this.formatDate(user.updatedAt)}
                                </td> <td className="aligntd">
                                  {user.customerDetail && !!user.customerDetail.length && user.customerDetail[0] && user.customerDetail[0].mobileNumber}
                                </td>



                                <td className="">
                                  {user.customerDetail && !!user.customerDetail.length && user.customerDetail[0] && user.customerDetail[0].context}
                                </td>
                                {/* <td className="">
                                  { user.customerDetail[0].hospital.charAt(0).toUpperCase() + user.customerDetail[0].hospital.slice(1)}
                                </td> */}
                                <td className="">
                                  {
                                    user.customerDetail && !!user.customerDetail.length && user.customerDetail[0] && user.customerDetail[0].language.charAt(0).toUpperCase() + user.customerDetail[0].language.slice(1)
                                  }
                                </td>

                                <td className="w-1">
                                  <a className="icon"
                                    onClick={(e) => this.openInfoPopup(e, user)}>
                                    {user.customerDetail && !!user.customerDetail.length && user.customerDetail[0] && user.customerDetail[0].entity.length > 0 ?
                                      <i className="fe fe-info"></i>
                                      :
                                      null
                                    }
                                  </a>
                                </td>
                              </tr>
                            ))
                            :
                            <Table.Row>
                              <Table.Col colSpan={7} >Sorry!  no data found.</Table.Col>
                            </Table.Row>
                          }

                        </Table.Body>
                      </Table>
                      {/* pagination */}
                      <div className="form-group" style={{ float: 'right' }}>
                        <label className="form-label">

                        </label>
                        <div className="selectgroup align-to-right paginations">
                          <span>



                            {this.getPagination()}
                          </span>

                          <i
                            className={
                              "fe " +
                              (this.props.match.params.page <= 1 ? "not-active " : "cursorPointer ") +
                              " fe-chevron-left"
                            }
                            onClick={(e) => this.pagination(e, (parseInt(this.props.match.params.page) - 1))}
                            disabled={this.props.match.params.page <= 1}>
                          </i>

                          <i
                            className={
                              "fe " +
                              (!nextPage ? "not-active " : "cursorPointer ") +
                              " fe-chevron-right"
                            }

                            onClick={(e) => this.pagination(e, (parseInt(this.props.match.params.page) + 1))}
                            disabled={!nextPage}></i>
                        </div>
                      </div>
                    </Card>


                  </div>

                  <div className="col col-sm-6 topChatBoc">

                    <div className="card card2">
                      <div className="card-header1">
                        <h3 className="card-title card-t1">
                          {
                            selectChatUser ?
                              selectChatUser.customerDetail && selectChatUser.customerDetail.length && selectChatUser.customerDetail[0] && 
                              selectChatUser.customerDetail[0].name
                              : 'Chat'
                          }
                        </h3>
                        <div className="card-options">


                        </div>
                      </div>
                      <div className="card-body admincard1" ref={this.chatDiv}
                        onScroll={(e) => this.handleScroll(e)}>
                        <ul className="list-unstyled 
                    list-separated">
                          {
                            messages.length ?
                              messages.map((message, childIndex) => (
                                <div>
                                  {
                                    (message.messageFrom === 'user' || message.messageFrom === 'customer') ?
                                      <li className="list-separated-item">
                                        <div className="row 
                      row align-items-center botchat">
                                          <div className="col col-auto">
                                            <span className="avatar avatar-md 
                    d-block usertextcolor 
                      userbackground1">
                                              C</span></div>
                                          <div className="col"><div>
                                            <span className="text-inherit" >
                                              {
                                                message
                                                  && Array.isArray(message.media)
                                                  && message.media.length > 0
                                                  && message.media[0].mimeType !== null
                                                  && message.media[0].mimeType.split('/')[0] === 'image'
                                                  // || message.media[0].mimeType === 'image/jpg' 
                                                  // || message.media[0].mimeType === 'image/png'
                                                  ? <img src={message.media[0].url} /> :
                                                  (
                                                    message
                                                      && Array.isArray(message.media)
                                                      && message.media.length > 0
                                                      ? <a target="_blank" href={message.media[0].url}>{message.media[0].originalName} </a> : message.message
                                                  )

                                              }
                                              <p >
                                                {this.formatDate(message.createdAt)}
                                              </p>
                                            </span></div>
                                            <div></div>
                                          </div>

                                        </div>
                                      </li>

                                      :


                                      <li className="list-separated-item">
                                        <div className="row row 
                    align-items-center">



                                          <div className="col">
                                            <div className="col01">
                                              <span className="text-inherit">
                                                {
                                                  message
                                                    && Array.isArray(message.media)
                                                    && message.media.length > 0
                                                    && message.media[0].mimeType !== null
                                                    && message.media[0].mimeType.split('/')[0] === 'image'
                                                    // || message.media[0].mimeType === 'image/jpg' 
                                                    // || message.media[0].mimeType === 'image/png'
                                                    ? <img src={message.media[0].url} /> :
                                                    (
                                                      message
                                                        && Array.isArray(message.media)
                                                        && message.media.length > 0
                                                        ? <a target="_blank" href={message.media[0].url}>{message.media[0].originalName} </a> : message.message
                                                    )

                                                }
                                                <p >
                                                  {this.formatDate(message.createdAt)}
                                                </p>
                                              </span></div></div>

                                          <div className="col col-auto">
                                            <span className="avatar avatar-md 
                      d-block usertextcolor 
                        userbackground">M</span>
                                          </div>


                                        </div>
                                      </li>




                                  }
                                </div>
                              ))
                              :

                              null
                          }

                        </ul>



                      </div>

                      <div className="car18">





                      </div>



                    </div>
                  </div>


                </Grid.Row>

              </div>
              :
              null
          }
          <InfoPopup
            openInfoPopup={this.state.openInfoPopup}
            onChange={this.closeInfoPopup}
          ></InfoPopup>
        </Page.Content>
      </SiteWrapperSuperAdmin >
    )
  }

}


const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    activeBot: state.auth.activeBot,
    siteLanguage: state.auth.siteLanguage

  }
}

function mapDispatchToProps(dispatch) {
  return {
    logOut: () => {
      dispatch(authActions.authLogout())
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SuperAdminDashboard));
