// @flow

import React from "react";
import {
  Form,
  Grid,
  Card,
  Button,
  Icon

} from "tabler-react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import authActions from "../../redux/auth/action";
import SiteWrapperSuperAdmin from "../SiteWrapper.superadmin.react";
import { validate } from '../../validate';
import clientService from "../../services/client";
import Buttonloader from '../../common/Loader/Buttonloader'
import { changeLanguage } from '../../../src/language/index';

const validationRules = [
  {
    field: 'xValue',
    validations: ['required'],
    name: 'Proximity Radius (in kms)'
  },
  {
    field: 'sValue',
    validations: ['required'],
    name: 'N (in mins)'
  },
  {
    field: 'nValue',
    validations: ['required'],
    name: 'M (in mins)'
  }
]

class Settings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: {
        xValue: 0,
        sValue: 0,
        nValue: 0,
      },
      message: {
        style: 'success',
        text: ''
      },
      isLoading: false,
      errors: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.setLoadingToggle = this.setLoadingToggle.bind(this);
  }

  handleChange = (e, field) => {
    const { fields } = this.state, val = e.target.value;
    fields[field] = val;
    this.setState({ fields });
    //this.validate();
  }

  setLoadingToggle(value) {
    this.setState({ isLoading: value })
  }

  // validate
  validate() {
    const { fields } = this.state;
    const { isValid, errors } = validate(fields, validationRules);
    this.setState({ errors });
    return isValid;
  }

  //form submition
  handleSubmit = async event => {
    event.preventDefault();
    console.log(this.validate())
    if (this.validate()) {
      this.setLoadingToggle(true);

      const data = {
        aiChatTime:{
          dormantNotificationTime:this.state.fields.sValue,
          endChatTime:this.state.fields.nValue
        },
        radius:this.state.fields.xValue
      }
      clientService.settings(data)
        .then(response => {
          this.props.user.settingData.radius = this.state.fields.xValue
          this.props.user.settingData.aiChatTime = {
            dormantNotificationTime:this.state.fields.sValue,
            endChatTime:this.state.fields.nValue
          }

          console.log(this.props.user)

          this.props.updateUserData(this.props.user);
          toast.success("Setting Changed successfully.")
          setTimeout(() => {
            this.props.history.goBack()
          }, 3000)

        })
        .catch(error => {
          this.setLoadingToggle(false);
          console.log(error)
          // this.setState(() => ({ message: { text: error.response.data.message, style: 'danger' } }));
          setTimeout(() => {
            this.handleNotification();
          }, 3000)
        })
    }
    else {
      console.log('eeeeeeee');
    }
  }

  componentDidMount() {
    document.title = "Voiceoc | Google Settings"

    if (this.props.user != undefined) {
      const { fields } = this.state;
      fields['xValue'] = this.props.user.settingData.radius;
      fields['nValue'] = this.props.user.settingData.aiChatTime.endChatTime
      fields['sValue'] = this.props.user.settingData.aiChatTime.dormantNotificationTime
      this.setState({
        fields
      });
      console.log(this.props)
    }
  }

  handleNotification() {
    this.setState(() => ({ message: { text: '', style: '' } }));
  }

  render() {
    const { message } = this.state;

    return (
      <SiteWrapperSuperAdmin>
        <Grid.Col lg={5} className="mx-auto ">
          <Card>
            <Card.Header>
              <Card.Title> {changeLanguage(this.props.siteLanguage,'google_setting')}</Card.Title>
            </Card.Header>
            <Card.Body>
              {!!message.text ?
                <div className={'alert alert-' + message.style} >
                  <p>{message.text}</p>
                  <Icon prefix="fa" name="times" onClick={this.handleNotification}></Icon>
                </div>
                :
                null
              }
              <form onSubmit={this.handleSubmit}>
                <Form.FieldSet>
                  <Form.Group
                    isRequired
                    label={changeLanguage(this.props.siteLanguage,'promisity_radius')}
                  >
                    <Form.Input name="xValue"
                      type="text"
                      placeholder={changeLanguage(this.props.siteLanguage,'promisity_radius')}
                      onChange={(e) => this.handleChange(e, 'xValue')}
                      value={this.state.fields.xValue}
                    />
                    <label style={{ display: this.state.errors.xValue ? 'block' : 'none' }} className="error">{this.state.errors.xValue}</label>
                  </Form.Group>

                  <Form.Group
                    isRequired
                    label="AI dormant time (in mins)"
                  >
                    <Form.Input name="sValue"
                      type="text"
                      placeholder="AI dormant time (in mins)"
                      onChange={(e) => this.handleChange(e, 'sValue')}
                      value={this.state.fields.sValue}
                    />
                    <label style={{ display: this.state.errors.sValue ? 'block' : 'none' }} className="error">{this.state.errors.sValue}</label>
                  </Form.Group>

                  <Form.Group
                    isRequired
                    label="AI end chat time (in mins)"
                  >
                    <Form.Input name="sValue"
                      type="text"
                      placeholder="AI end chat time (in mins)"
                      onChange={(e) => this.handleChange(e, 'nValue')}
                      value={this.state.fields.nValue} />
                    <label style={{ display: this.state.errors.nValue ? 'block' : 'none' }} className="error">{this.state.errors.nValue}</label>
                  </Form.Group>
                </Form.FieldSet>

                {!this.state.isLoading ?
                  <Button color="primary btn-block" type="submit">{changeLanguage(this.props.siteLanguage,'change')}</Button>
                  :
                  <Buttonloader loading={this.state.isLoading}></Buttonloader>
                }
              </form>
            </Card.Body>
          </Card>
        </Grid.Col>
        <ToastContainer autoClose={5000} position={'bottom-right'} />
      </SiteWrapperSuperAdmin >
    )
  }

}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    siteLanguage:state.auth.siteLanguage
  }
}
function mapDispatchToProps(dispatch) {
  return {
    updateUserData: (payload) => {
      dispatch(authActions.updateUserData(payload))
    }
  }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));
