import React from "react";
import Popup from "reactjs-popup";
import { Grid, Card, Table, Page, colors, Form } from "tabler-react";

class ViewTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.openPopup,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.props.onChange(false);
  }

  render() {
    const openPopup = this.props.openPopup.open;
    const templateData = this.props.openPopup.data;
    return (
      <div>
        <Popup open={openPopup} onClose={this.closeModal} closeOnDocumentClick>
          {templateData ? (
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">
                  <span>Category: {templateData.category} </span>
                  <br />
                  <span>Template Id: {templateData.templateId} </span>
                </h3>

                <div className="card-options">
                  <a
                    className="card-options-remove cursorPointer"
                    data-toggle="card-remove"
                    onClick={() => {
                      this.closeModal();
                    }}
                  >
                    <i className="fe fe-x" />
                  </a>
                </div>
              </div>
              <div className="card-body">{templateData.description}</div>
              <div className="card-body">
                <Table cards={true} striped={true} responsive={true} className="table-vcenter">
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>Sl.</Table.ColHeader>
                      <Table.ColHeader>Fields</Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {templateData.dynamicFields.length ? (
                      templateData.dynamicFields.map((optionObject, tableindex) => (
                        <Table.Row key={tableindex}>
                          <Table.Col>{tableindex + 1}</Table.Col>
                          <Table.Col>{optionObject}</Table.Col>
                        </Table.Row>
                      ))
                    ) : (
                      <Table.Row>
                        <Table.Col colSpan={7}>Sorry! no option found.</Table.Col>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </div>
            </div>
          ) : null}
        </Popup>
      </div>
    );
  }
}

export default ViewTemplate;
