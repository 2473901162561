import axios from "../http";
import http, { axiosBasicAuth } from '../http';
const API_URL = process.env.REACT_APP_apiUrl;


async function create(fields) {
  const url = `${API_URL}admins/admin`;
  let response = await axios.post(url, fields);
  return response.data;
}

async function getClients(page, search = false, limit = 10) {
  let params = 'page=' + page + '&limit=' + limit;
  if (search) {
    params += '&search=' + search
  }
  const url = `${API_URL}admins/adminList?` + params;
  let response = await axios.get(url);
  return response.data;
}

async function getClient(id) {
  const url = `${API_URL}admins/admin/` + id;
  let response = await axios.get(url);
  return response.data;
}

async function update(fields, id) {
  const url = `${API_URL}admins/admin/` + id;
  let response = await axios.patch(url, fields);
  return response.data;
}



async function syncDoctors() {
  const url = `${API_URL}admins/doctor/sync_doctors/`;
  let response = await axios.get(url);
  return response.data;
}
async function syncSpecialization() {
  const url = `${API_URL}admins/doctor/sync_specializations/`;
  let response = await axios.get(url);
  return response.data;
}

async function blockUnblock(id, data) {
  const url = `${API_URL}admins/admin/` + id;
  let response = await axios.patch(url, data);
  return response.data;
}

async function changePassword(data) {
  const url = `${API_URL}admins/password`;
  let response = await axios.patch(url, data);
  return response.data;
}

async function settings(data) {
  const url = `${API_URL}admins/setting`;
  let response = await axios.post(url, data);
  return response.data;
}

async function getUsers(page, type = 'erp', search = false) {
  // if(type === 'csv'){
  //   type = 'CSV';
  // }
  let params = 'page=' + page + '&addType=' + type;
  const url = `${API_URL}users/userList?` + params;
  let response = await axios.get(url);
  return response.data;
}
async function getNotification(page, type = 'erp', search = false) {
  // if(type === 'csv'){
  //   type = 'CSV';
  // }
  let params = 'page=' + page;
  // let params = 'page=' + page + '&addType=' + type;
  const url = `${API_URL}notification/${type}?` + params;
  let response = await axios.get(url);
  return response.data;
}
async function userImportByCsv(data) {
  const url = `${API_URL}users/uploadUser`;
  let response = await axios.post(url, data);
  return response.data;
}

async function notificationImportByCsv(data) {
  const url = `${API_URL}notification/uploadNotification`;
  let response = await axios.post(url, data);
  return response.data;
}

async function notificationCreate(data) {
  const url = `${API_URL}notification/manual`;
  let response = await axios.post(url, data);
  return response.data;
}

async function uploadFile(data) {
  const url = `${API_URL}admins/upload/media`;
  let response = await axios.post(url, data);
  return response.data;
}

async function userCreate(data) {
  const url = `${API_URL}users/addUser`;
  let response = await axios.post(url, data);
  return response.data;
}

async function endChat(data) {
  const url = `${API_URL}admins/chat/end`;
  let response = await axios.post(url, data);
  return response.data;
}

async function deleteAdmin(id) {
  const url = `${API_URL}admins/` + id;
  let response = await axios.delete(url);
  return response.data;
}

async function getBot(botId, data = false) {
  let url = `${API_URL}bot/analytics/` + botId;
  if ((data && data.week) || !data) {
    url = url + '?week=true'
  }
  else if (data && data.month && data.year) {
    url = url + '?month=' + data.month + '&year=' + data.year;
  }
  else if (data && data.year) {
    url = url + '?year=' + data.year;
  }
  else if (data && data.startDate && data.endDate) {
    url = url + '?startDate=' + data.startDate + '&endDate=' + data.endDate;
  }
  let response = await axios.get(url);
  return response.data;
}

async function getchats(botId, searchData = false) {
  //let params = 'page=' + page+'&addType='+type;
  var url = `${API_URL}bot/chats/` + botId;
  if (searchData) {
    url = url + '?filter=' + searchData;
  }
  let response = await axios.get(url);
  return response.data;
}

async function exportData(botId) {
  const url = `${API_URL}bot/download-analytics/` + botId;
  let response = await axios.get(url);
  return response;
}

async function getChatUsers(page, search, limit = 10, adminId = false, sortKey = false, sortType = false) {
  let params = 'page=' + page + '&limit=' + limit;
  if (search) {
    params += '&search=' + search
  }
  console.log('sortKey', sortKey)
  if (sortKey && sortType) {
    params += '&sortKey=' + sortKey + '&sortType=' + sortType;
  }
  if (adminId) {
    params += '&adminId=' + adminId
  }
  const url = `${API_URL}admins/chatList?` + params;
  let response = await axios.get(url);
  return response.data;
}

async function getAllChatUsers(page, search, limit = 10, adminId = false, sortKey = false, sortType = false) {
  let params = 'page=' + page + '&limit=' + limit;
  if (search) {
    params += '&search=' + search
  }
  console.log('sortKey', sortKey)
  if (sortKey && sortType) {
    params += '&sortKey=' + sortKey + '&sortType=' + sortType;
  }
  if (adminId) {
    params += '&adminId=' + adminId
  }
  const url = `${API_URL}admins/customerList?` + params;
  let response = await axios.get(url);
  return response.data;
}
async function getMessages(agentId, page = 1) {
  let params = 'page=' + page;
  const url = `${API_URL}admins/messages/` + agentId + `?` + params;
  let response = await axios.get(url);
  return response.data;
}

async function getAllMessages(agentId, page = 1) {
  let params = 'page=' + page;
  const url = `${API_URL}admins/customerChats/` + agentId + `?` + params;
  let response = await axios.get(url);
  return response.data;
}

async function endChatClient(id) {
  const url = `${API_URL}admins/end/allChat/` + id;
  let response = await axios.post(url);
  return response.data;
}

async function getCount() {
  const url = `${API_URL}admins/details`;
  let response = await axios.get(url);
  return response.data;
}


async function getChartData(startDate, endDate, unique, context) {

  //   var dateString = 'Mon Jun 30 2014 00:00:00';

  //   endDate = new Date(endDate);

  // // // seconds * minutes * hours * milliseconds = 1 day 
  // var day = 60 * 60 * 24 * 1000;

  //  endDate = new Date(endDate.getTime() + day);
  //  endDate = endDate.toISOString();
  // endDate = endDate.setUTCHours(23,59,59)
  // endDate.setDate(endDate.getDate() + 1);
  if (context.length === 0) {
    var url = `${API_URL}admins/analytics/user?startDate=${startDate}&endDate=${endDate}&unique=${unique}`;
  } else {
    var url = `${API_URL}admins/analytics/user?startDate=${startDate}&endDate=${endDate}&unique=${unique}&context=${context}`;

  }
  let response = await axios.get(url);
  return response.data;
}


async function getChartUserData(startDate, endDate, unique) {
  // endDate = new Date(endDate);

  // // // seconds * minutes * hours * milliseconds = 1 day 
  // var day = 60 * 60 * 24 * 1000;

  //  endDate = new Date(endDate.getTime() + day);
  //  endDate = endDate.toISOString();
  const url = `${API_URL}admins/analytics/context?startDate=${startDate}&endDate=${endDate}&unique=${unique}`;
  let response = await axios.get(url);
  return response.data;
}

async function getCountData() {
  const url = `${API_URL}admins/dashboard`;
  let response = await axios.get(url);
  return response;
}

async function getContext() {

  const url = `${API_URL}admins/config`;
  let response = await axios.get(url);
  return response.data;
}


async function getfeedBack(startDate, endDate, type, context) {
  let params = `fromDate=${startDate}&toDate=${endDate}`;
  if (context != '' && context != undefined) {
    params += `&context=${context}`
  }
  const url = `${API_URL}feedback/rating/${type}?` + params;
  let response = await axios.get(url);
  return response.data;
}

async function getAvgCst() {

  const url = `${API_URL}feedback/cst`;
  let response = await axios.get(url);
  return response.data;
}

async function getPromotion(startDate, endDate) {
  let params = `fromDate=${startDate}&toDate=${endDate}`;
  const url = `${API_URL}/feedback/promotios?` + params;
  let response = await axios.get(url);
  return response.data;
}

async function getWordCount(startDate, endDate) {
  let params = `?startDate=${startDate}&endDate=${endDate}`

  var url = `${API_URL}feedback/words` + params;

  let response = await axios.get(url);
  return response.data;
}

async function getDistribution(year) {
  let params = ''
  if (year) {
    params = `?year=${year}`
  }
  var url = `${API_URL}feedback/distribution${params}`;

  let response = await axios.get(url);
  return response.data;
}
async function getHospitalList(startDate, endDate) {
  let params = 'page=1' + '&limit=50';
  var url = `${API_URL}admins/hospitals?` + params;

  let response = await axios.get(url);
  return response.data;
}
async function getLeadDashboard(page, search = false, limit = 10, startDate, endDate) {

  let params = 'page=' + page + '&limit=' + limit;
  if (search.length > 0) {
    params += '&search=' + search
  }
  if (startDate && endDate) {
    params += `&fromDate=${startDate}&toDate=${endDate}`;
  }
  const url = `${API_URL}lead?` + params;
  let response = await axios.get(url);
  return response.data;
}

async function getReportLogs(startDate, endDate) {

  let end = new Date(endDate)
  end.setDate(end.getDate() + 1)
  end.setTime(end.getTime() - 1)
  const _startDate = new Date(startDate).toLocaleDateString("en-US");
  const _endDate = new Date(end).toLocaleDateString("en-US");
  console.log(_startDate, _endDate)
  var url = `${API_URL}admins/reportLogs?fromDate=${_startDate}&toDate=${_endDate}`;
  let response = await axios.get(url);
  console.log(response)
  return response.data;
}
async function getLeadDashboardDetails(id) {

  var url = `${API_URL}lead/${id}`;

  let response = await axios.get(url);
  return response.data;
}

export async function getPaymentLogs(startDate, endDate) {
  const _startDate = new Date(startDate).toISOString();
  const _endDate = new Date(endDate).toISOString();
  let url = `${API_URL}admins/paymentLogs?fromDate=${_startDate}&toDate=${_endDate}`;
  console.log(url)
  return axios.get(url);
}

export async function logoutAdmin(id) {
  let url = `${API_URL}admins/setLiveStatusToFalse/${id}`;
  let response = await axios.post(url, {});
  return response.data;
}

export async function getUploadNotifications() {
  const url = `${API_URL}notification/get-uploaded-csv`;
  let response = await axios.get(url);
  return response.data;
}

async function getAgentLogs(email) {
  let url = `${API_URL}admins/agentLogs?limit=100&page=1`;
  let response = await axios.post(url, { email: email });
  return response.data;
}

async function downloadAgentLogs(email) {
  let url = `${API_URL}admins/download-agentLogs?limit=100&email=${email}`;
  let response = await axios.get(url);
  return response.data;
}

export default {
  getAgentLogs,
  downloadAgentLogs,
  create,
  getClients,
  getReportLogs,
  getClient,
  update,
  blockUnblock,
  changePassword,
  getUsers,
  userImportByCsv,
  userCreate,
  getBot,
  getchats,
  exportData,
  deleteAdmin,
  settings,
  getChatUsers,
  getMessages,
  uploadFile,
  endChat,
  getAllChatUsers,
  getAllMessages,
  endChatClient,
  getCount,
  getChartData,
  getChartUserData,
  getCountData,
  getContext,
  notificationImportByCsv,
  notificationCreate,
  getNotification,
  getfeedBack,
  getAvgCst,
  getPromotion,
  getWordCount,
  getDistribution,
  getHospitalList,
  getLeadDashboard,
  getLeadDashboardDetails,
  getUploadNotifications,
  syncDoctors,
  syncSpecialization
}
