import axios from "../http";
const API_URL = process.env.REACT_APP_apiUrl;


async function create(fields) {
  const url = `${API_URL}bot`;
  let response = await axios.post(url, fields);
  return response.data;
}

async function getClientBots(id,page=1,limit=10) {
  let params = 'clientId='+id+'&page=' + page+'&limit='+limit;
  const url = `${API_URL}bot/list?` + params;
  let response = await axios.get(url);
  return response.data;
}

async function getBot(id) {
  const url = `${API_URL}bot/` + id;
  let response = await axios.get(url);
  return response.data;
}

async function blockUnblock(id, data) {
  const url = `${API_URL}bot/blockUnblock/` + id;
  let response = await axios.patch(url, data);
  return response.data;
}

async function updateBot(data) {
  const url = `${API_URL}bot/`;
  let response = await axios.put(url, data);
  return response.data;
}

async function mapBotQuestions(data) {
  const headers = {
    'Content-Type': 'application/json',
  }
  const url = `${API_URL}bot/assign-questions`;
  let response = await axios.post(url, data,{
     headers: headers
  });
  return response.data;
}

async function addQuestions(data) {
  const url = `${API_URL}bot/add-questions`;
  let response = await axios.post(url, data);
  return response.data;
}

async function getTempltes(page=1) {
  
  const url = `${API_URL}template?page=`+page+`&limit=`+25;
  let response = await axios.get(url);
  return response.data;
}

async function getButtons(page=1) {
  
  const url = `${API_URL}button?page=`+page+`&limit=`+25;
  let response = await axios.get(url);
  return response.data;
}
async function createTemplate(fields) {
  const url = `${API_URL}template`;
  let response = await axios.post(url, fields);
  return response.data;
}
async function createButton(fields) {
  const url = `${API_URL}button`;
  let response = await axios.post(url, fields);
  return response.data;
}

async function updateTemplate(data,templateId) {
  const url = `${API_URL}template/`+templateId;
  let response = await axios.put(url, data);
  return response.data;
}
async function deleteTemplate(templateId){
  const url = `${API_URL}template/`+templateId;
  let response = await axios.delete(url);
  return response.data;
}
async function updateButton(data,templateId) {
  const url = `${API_URL}button/`+templateId;
  let response = await axios.put(url, data);
  return response.data;
}
async function deleteButtons(templateId){
  const url = `${API_URL}button/`+templateId;
  let response = await axios.delete(url);
  return response.data;
}




export default {
  deleteButtons,
  createButton,
  getButtons,
  updateButton,
  create,
  getClientBots,
  getBot,
  blockUnblock,
  updateBot,
  mapBotQuestions,
  addQuestions,
  getTempltes,
  createTemplate,
  updateTemplate,
  deleteTemplate
}
