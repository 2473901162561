// @flow

import React from "react";
import {
  Form,
  Grid,
  Card,
  Button,
  Icon,
  Page
} from "tabler-react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import SiteWrapperSuperAdmin from "../SiteWrapper.superadmin.react";
import { validate } from '../../validate';
import clientService from "../../services/client";
import authActions from "../../redux/auth/action";
import Buttonloader from '../../common/Loader/Buttonloader'
import { changeLanguage } from '../../../src/language/index';

const validationRules = [
  {
    field: 'name',
    validations: ['required', 'min:3', 'max:50'],
    name: 'Agent Name'
  },
  {
    field: 'email',
    validations: ['required', 'email'],
    name: 'Email'
  },
  // {
  //   field: 'category',
  //   validations: ['required'],
  //   name: 'Category'
  // },
  {
    field: 'agentId',
    validations: ['required'],
    name: 'Agent Id'
  },
  {
    field: 'agentType',
    validations: ['required'],
    name: 'Agent Type'
  },
];

class ClientCreate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: {
        name: '',
        email: '',
        // category: '',
        agentId: '',
        hospital: '',
        agentType: '',
      },
      isLoading: false,
      message: {
        style: 'success',
        text: ''
      },
      errors: {},
      hospitalList: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.setLoadingToggle = this.setLoadingToggle.bind(this);

  }

  //handle change
  handleChange = (e, field) => {
    const { fields } = this.state, val = e.target.value;
    fields[field] = val;
    this.setState({ fields });
  }

  setLoadingToggle(value) {
    this.setState({ isLoading: value })
  }

  //submit form
  handleSubmit = async event => {
    event.preventDefault();
    console.log(this.validate())

    if (this.validate()) {
      this.setLoadingToggle(true);
      let data = {
        name: this.state.fields.name,
        email: this.state.fields.email,
        // category: this.state.fields.category,
        agentId: this.state.fields.agentId,
        agentType: this.state.fields.agentType,
        // language: this.state.fields.category,
        // hospitalId: this.state.fields.hospital,
        password: "123456"
      }
      console.log(data)
      clientService.create(data)
        .then(response => {
          toast.success("Agent created successfully.")
          setTimeout(() => {
            this.props.history.push("/admin/clients/1");
          }, 3000)

        })
        .catch(error => {
          this.setState(() => ({ message: { text: error.response.data.message, style: 'danger' } }));
          setTimeout(() => {
            this.handleNotification();
          }, 3000)
          this.setLoadingToggle(false);
        })

    }
  }

  handleNotification() {
    this.setState(() => ({ message: { text: '', style: '' } }));
  }

  validate() {
    const { fields } = this.state;
    const { isValid, errors } = validate(fields, validationRules);
    console.log({errors})
    this.setState({ errors });
    return isValid;
  }

  componentDidMount() {
    document.title = "Voiceoc | Create Admin";
    clientService.getHospitalList().then((res) => {
      this.setState({
        hospitalList: res.data.result
      })
    })

  }
  render() {

    const { message, hospitalList } = this.state;
    return (


      <SiteWrapperSuperAdmin>
        <Page.Content>
          <Grid.Row>
            <Grid.Col lg={5} className="mx-auto ">
              <Card>
                <Card.Header>
                  <Card.Title>{changeLanguage(this.props.siteLanguage, 'add_new_agents')}</Card.Title>
                </Card.Header>
                <Card.Body>
                  {!!message.text ?
                    <div className={'alert alert-' + message.style} >
                      <p>{message.text}</p>
                      <Icon prefix="fa" name="times" onClick={this.handleNotification}></Icon>
                    </div>
                    :
                    null
                  }
                  <form onSubmit={this.handleSubmit}>
                    <Form.FieldSet>
                      <Form.Group
                        isRequired
                        label={changeLanguage(this.props.siteLanguage, 'agent_name')}
                      >
                        <Form.Input name="name"
                          onChange={(e) => this.handleChange(e, 'name')}
                          placeholder={changeLanguage(this.props.siteLanguage, 'agent_name')}
                          value={this.state.fields.name} />
                        <label style={{ display: this.state.errors.name ? 'block' : 'none' }} className="error">{this.state.errors.name}</label>
                      </Form.Group>

                      <Form.Group

                        isRequired
                        label={changeLanguage(this.props.siteLanguage, 'email_Id')}
                      >
                        <Form.Input name="email"
                          onChange={(e) => this.handleChange(e, 'email')}
                          placeholder={changeLanguage(this.props.siteLanguage, 'email_Id')}
                          value={this.state.fields.email} />
                        <label style={{ display: this.state.errors.email ? 'block' : 'none' }} className="error">{this.state.errors.email}</label>
                      </Form.Group>
                      <Form.Group
                        isRequired
                        label={changeLanguage(this.props.siteLanguage, 'agents_Id')}
                      >
                        <Form.Input name="agentId"
                          onChange={(e) => this.handleChange(e, 'agentId')}
                          placeholder='Agent Id'
                          value={this.state.fields.agentId} />
                        <label style={{ display: this.state.errors.agentId ? 'block' : 'none' }} className="error">{this.state.errors.agentId}</label>
                      </Form.Group>
                      <div>
                        <p>Please select Agent Type*</p>

                        <input type="radio" id="chatAgent" name="agentType" value="chatAgent"
                          onChange={(e) => this.handleChange(e, 'agentType')}
                        />
                        <label for="chatAgent">Chat Agent</label>
                        <br />



                        <input type="radio" id="leadAgent" name="agentType" value="leadAgent" 
                          onChange={(e) => this.handleChange(e, 'agentType')}
                        />
                        <label for="leadAgent">Lead Agent</label>
                        <label style={{ display: this.state.errors.agentType ? 'block' : 'none' }} className="error">{this.state.errors.agentType}</label>

                      </div>



                      {/* <Form.Group label={'Language'}>
                        <Form.Select name={changeLanguage(this.props.siteLanguage, 'category')}
                          onChange={(e) => this.handleChange(e, 'category')}>
                          <option>

                            {changeLanguage(this.props.siteLanguage, 'please_select_category')}
                          </option>
                          <option value='en'>
                            En
                          </option>
                          <option value='ar'>
                            Ar
                          </option>
                          <option value='ml'>
                            Ml
                          </option>
                        </Form.Select>
                        <label style={{ display: this.state.errors.category ? 'block' : 'none' }} className="error">{this.state.errors.category}</label>
                      </Form.Group> */}
                      {/* <Form.Group label='Hospital'>
                        <Form.Select name='hospital'
                          onChange={(e) => this.handleChange(e, 'hospital')}>
                          <option>
                           
                            Please select hospital
                            </option>
                            {
                              hospitalList && 
                              hospitalList.map((result)=>{
                                return(
                                  <option value={result.id}>
                                    {result.name_en} 
                                  </option>
                                )
                              })
                            }
                         
                         
                        </Form.Select>
                        <label style={{ display: this.state.errors.hospital ? 'block' : 'none' }} className="error">{this.state.errors.hospital}</label>
                      </Form.Group> */}
                    </Form.FieldSet>

                    {!this.state.isLoading ?
                      <Button color="primary btn-block" type="submit">{changeLanguage(this.props.siteLanguage, 'create')}</Button>
                      :
                      <Buttonloader loading={this.state.isLoading}></Buttonloader>
                    }
                  </form>
                  <ToastContainer autoClose={5000} position={toast.POSITION.BOTTOM_RIGHT} />
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapperSuperAdmin >
    )
  }

}


const mapStateToProps = (state) => {
  return {
    siteLanguage: state.auth.siteLanguage
  }
}
const bindActions = (dispatch) => {
  return {
    updateToastState: (payload) => dispatch(authActions.updateToastState(payload))
  }
}
export default withRouter(connect(mapStateToProps, bindActions)(ClientCreate));
//export default ClientCreate;
