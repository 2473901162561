import React, { useEffect, useState } from 'react'
import C3Chart from "react-c3js";
import DatePicker from "react-datepicker";
import {
    Grid,
    Card,
    Table,
    Page,
    StatsCard,
    Form, Button
} from "tabler-react";
import { getPaymentLogs } from '../../services/client';

export default function PaymentLogs() {

    const [paymentLogs, setPaymentLogs] = useState(
        {
            columns: [
                ['Failure', 0],
                ['Success', 0],
            ],
            type: 'donut',
        }
    )

    const [fromDate, setFromDate] = useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
    const [toDate, setToDate] = useState(new Date())


    useEffect(() => {
        getLogs();
    }, [])

    const getLogs = async () => {
        try {
            let end = new Date(toDate)
            end.setDate(end.getDate() + 1)
            end.setTime(end.getTime() - 1)
            let res = await getPaymentLogs(fromDate, end)
            let successObj = res.data.data.find(el => el.status == 'Success') 
            let successCount = successObj ? successObj.count :  0;

            let failObj = res.data.data.find(el => el.status == 'Failure') 
            let failCount = failObj ? failObj.count :  0;

            setPaymentLogs(prev => ({ ...prev, columns: [
                ['Failure', failCount],
                ['Success', successCount],
            ] }))
            console.log("payment", res.data)

        } catch (error) {
            console.error("error", error)
        }
    }







    return (


        <>

            <div className="box-al mar-40">
                <form onSubmit={e => { e.preventDefault(); getLogs() }}>
                    <div className="row">
                        <div className="col col-6 col-sm-4 col-lg-5">
                            <label className="form-label">From Date</label>
                            <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="DD/MM/YYYY"
                                selected={fromDate}
                                onChange={(e) => setFromDate(e)}
                                maxDate={new Date()}
                            />
                        </div>

                        <div className="col col-6 col-sm-4 col-lg-5">
                            <label className="form-label">To Date</label>
                            <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                onChange={(e) => setToDate(e)}
                                placeholderText="DD/MM/YYYY"
                                selected={toDate}
                                minDate={new Date(fromDate)}
                                maxDate={new Date()}
                            />
                        </div>

                        <div className="col col-6 col-sm-4 col-lg-2">
                            <br />
                            <Button color="primary   sub1" type="submit" >
                                Submit
                            </Button>
                        </div>
                    </div>
                </form>
            </div>


            <div className="col col-12 col-sm-12 col-lg-12">
                <div className="box-3rd card card101">
                    <div className="bar-context row">
                        <div className=" pull-left col col-12 col-sm-12 col-lg-7">
                            <h3 className="card-title"> Payment Logs </h3>
                        </div>
                    </div>
                    <div className="card-body p-3 text-center">
                        <C3Chart
                            data={paymentLogs}
                            legend={{
                                show: true
                            }}
                            donut={{
                                title: "Payment Logs"
                            }}
                            colors={{
                                Success: '#ff0000',
                                Failure: '#00ff00',
                            }}
                        />

                    </div>
                    <div>
                    </div>
                </div>
            </div>



        </>
    )
}
