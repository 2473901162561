// @flow

import * as React from "react";
import { NavLink, withRouter, Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import loginService from "../services/user";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { changeLanguage } from '../../src/language/index'


import {
  Site,
  RouterContextProvider,
} from "tabler-react";
import authActions from "../redux/auth/action";
import $ from 'jquery';
// import type { NotificationProps } from "tabler-react";



class SiteWrapperSuperAdmin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      siteLanguage:'',
      showLang:'ENG',
      user: this.props.user,
    }
    
    this.routeChange = this.routeChange.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    // this.props.setLang({"siteLanguage": 'en'})
  }

  
getMenu(){
  let navBarItems
   navBarItems = [
    {
      value: changeLanguage(this.props.siteLanguage,'dashboard'),
      to:  this.state.user.agentType=="leadAgent"? "/agent/lead-dashboard/1" :"/agent/dashboard/1", 
      icon: "home",
      LinkComponent: withRouter(NavLink),
      useExact: true,
    },
    // {
    //   value: "Buttons",
    //   to: "/agent/button",
    //   icon: "button",
    //   LinkComponent: withRouter(NavLink),
    //   useExact: true,
    // },
  ];
  return navBarItems;
}
  routeChange(path) {
    if (path === '/login') {
      loginService.logout()
        .then(response => {
          console.log(response)
          console.log(response.statusCode)
          if (response.statusCode === 200) {
            this.props.logOut();
            this.props.history.push("/login");

          } else if(response.statusCode === 201){
            this.props.logOut();
            this.props.history.push("/login");
          } else {
            toast.error(response.data.message)
          }
          //this.setLoadingToggle(false);
        })
        .catch((err) => {
          console.log(err)
          this.props.logOut();
          this.props.history.push("/login");
          //this.setLoadingToggle(false);
        });

    }
    this.props.history.push(path);
  }

  changeLanguage (language) {
    if(this.props.siteLanguage === 'ar'){
      this.props.setLang({"siteLanguage": 'en'})
      this.setState({siteLanguage:'en'})
      this.setState({showLang:'ENG'})
    }else{
      this.props.setLang({"siteLanguage": 'ar'})
      this.setState({siteLanguage:'ar'})
      this.setState({showLang:'ARABIC'})

    } 
    // window.location.reload(1);
}

  componentDidMount() {
    this.menuSelection();
    //const { user } = this.props;
    // if (user.role === "client") {
    //   this.props.history.push('/client/dashboard');
    // }
    // this.props.logOut();
    //this.props.history.push('/');
  }

  menuSelection() {
    var url = window.location.pathname;
    let splitUrl = url.split('/');
    if (splitUrl.length && splitUrl[2] !== undefined && splitUrl[2] === 'dashboard') {
      if (splitUrl[3] !== undefined) {
        $('.nav-link').each(function (index, ele) {
          let hrefVar = $(ele).attr('href');
          if (hrefVar !== undefined) {
            let splitHref = hrefVar.split('/')
            if (splitHref.length && splitHref[2] !== undefined && splitHref[2] === 'dashboard') {
              $(ele).addClass("active");
            }
          }

        })

      }
    }
  }

  render() {
    const { user } = this.props;

    // const redirectUrl = user.role === 'superadmin' ? "/admin/dashboard" : '';
    const redirectUrl = user.role === 'admin' ? 
    ( user.agentType === 'leadAgent' ? '/agent/lead-dashboard/1' : "/agent/dashboard/1" ) : '';
    // return(
    //   <pre>
    //      {redirectUrl}
    //   </pre>
    // )
    return (
      user.role === 'superadmin' ? <Redirect to={redirectUrl} /> :
        <Site.Wrapper
          headerProps={{
            to: '/',
            alt: "Voiceoc",
            imageURL: process.env.REACT_APP_publicUrl + '/images/logo-voiceoc-new.svg',
            accountDropdown: {
              avatarURL: `${process.env.REACT_APP_baseUrl}/content/logo.png`,
              name: user.name,
              description: "Agent",
              options: [
                {
                  icon: "unlock", value: changeLanguage(this.props.siteLanguage,'change_password'), onClick: () => {
                    this.routeChange('/agent/change-password');
                  }
                },
                { isDivider: true },

                // {
                //   icon: "globe", value: `Change Language: ${this.state.showLang} | ${this.state.showLang === 'ARABIC' ? 'ENG' : 'ARABIC'}`, onClick: () => {
                //     this.changeLanguage();
                //   }
                // },


                user.agentType == 'chatAgent' &&
                {
                  icon: "unlock", value:  changeLanguage(this.props.siteLanguage,'button'), onClick: () => {
                    this.routeChange('/agent/button');
                  }
                },

                {
                  icon: "log-out", value: changeLanguage(this.props.siteLanguage,'signout'), onClick: () => {
                    this.routeChange('/login');
                  }
                },
              ],
            },
          }}
          navProps={{ itemsObjects: this.getMenu() }}
          routerContextComponentType={withRouter(RouterContextProvider)}
          footerProps={{
            copyright: (
              <React.Fragment>
                Copyright © 2020

                <Link to="/"> Voiceoc Innovations Pvt Ltd. </Link>
                All Rights Reserved
              </React.Fragment>
            ),
          }}
        >
          {this.props.children}
          <ToastContainer autoClose={5000} position={'bottom-right'} />
        </Site.Wrapper >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    siteLanguage: state.auth.siteLanguage

  }
}

function mapDispatchToProps(dispatch) {
  return {
    logOut: () => {
      dispatch(authActions.authLogout())
    },
    setLang: (payload) => {
      dispatch(authActions.setLang(payload))
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteWrapperSuperAdmin));

