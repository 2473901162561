// @flow

import React from "react";
import {
  Form,
  Grid,
  Card,
  Button,
  Icon,
  Page

} from "tabler-react";
import Select from 'react-select';
import SiteWrapperSuperAdmin from "../SiteWrapper.superadmin.react";
import clientService from "../../services/client";
import { validate } from '../../validate';
import { toast, ToastContainer } from 'react-toastify';
import Buttonloader from '../../common/Loader/Buttonloader'
import 'react-toastify/dist/ReactToastify.css';


const validationRules = [
  {
    field: 'name',
    validations: ['required', 'min:3', 'max:50'],
    name: 'Agent Name'
  },
  {
    field: 'email',
    validations: ['required', 'email'],
    name: 'Email'
  },
  {
    field: 'agentId',
    validations: ['required'],
    name: 'Agent Id'
  },
  // {
  //   field: 'category',
  //   validations: ['required'],
  //   name: 'Category'
  // },
  // {
  //   field: 'hospital',
  //   validations: ['required'],
  //   name: 'Hospital'
  // },

  {
    field: 'agentType',
    validations: ['required'],
    name: 'Agent Type'
  },
];

class ClientEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      categories: [{ value: 'En', label: 'En' }, { value: 'Ar', label: 'Ar' }],
      fields: {
        name: '',
        email: '',
        category: 'en',
        agentId: '',
        agentType: '',
        seletctedCategory: null,
        hospitalList: '',
        hospitalId: '',
        hospital: ''
      },
      message: {
        style: 'success',
        text: ''
      },
      isLoading: false,
      errors: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.setLoadingToggle = this.setLoadingToggle.bind(this);

  }

  setLoadingToggle(value) {
    this.setState({ isLoading: value })
  }

  //handle change
  handleChange = (e, field) => {
    const { fields } = this.state, val = e.target.value;
    fields[field] = val;
    console.log('fields[field]', fields)
    console.log('fields[field]', val)
    this.setState({ fields });
    this.validate();
  }


  handleNotification() {
    this.setState(() => ({ message: { text: '', style: '' } }));
  }

  validate() {
    const { fields } = this.state;
    const { isValid, errors } = validate(fields, validationRules);
    this.setState({ errors });
    return isValid;
  }

  componentDidMount() {
    document.title = "Voiceoc | Edit Admin"
    clientService.getHospitalList().then((res) => {
      this.setState({
        hospitalList: res.data.result
      })
    })
    clientService.getClient(this.props.match.params.id)
      .then(res => {
        if (res.statusCode === 200) {

          console.log(res.data)
          const client = res.data,
            fields = {
              name: client.name,
              email: client.email,
              // seletctedCategory: { value: client.category, label: client.category },
              agentId: client.agentId,
              agentType: client.agentType,
              hospital: client.hospitalId
            };

          console.log('fieldsfieldsfields', fields)
          this.setState({ fields });
        } else if (res.statusCode === 400) {
          // const { alert } = this.state;
          // alert.show = true;
          // alert.style = 'danger';
          // alert.text = res.error.message || 'Network error';
          // this.setState({ alert });
        }
      })
      .catch(err => {
        console.log('ERROR', err);
      })


  }

  //submit form
  handleSubmit = async event => {
    event.preventDefault();

    if (this.validate()) {
      this.setLoadingToggle(true);
      let data = {
        name: this.state.fields.name,
        email: this.state.fields.email,
        // category: this.state.fields.seletctedCategory.value,
        agentId: this.state.fields.agentId,
        agentType: this.state.fields.agentType,
        language: this.state.fields.category,
        // hospitalId: this.state.fields.hospital,
      }

      clientService.update(data, this.props.match.params.id)
        .then(response => {
          toast.success("Admin updated successfully.")
          setTimeout(() => {
            this.props.history.push("/admin/clients/1");
          }, 3000)
        })
        .catch(error => {
          this.setState(() => ({ message: { text: error.response.data.message, style: 'danger' } }));
          setTimeout(() => {
            this.handleNotification();
          }, 3000)
          this.setLoadingToggle(false);
        })

    }
  }

  //listen category change
  selectCategory = selectedCategory => {
    const { fields } = this.state;
    fields['seletctedCategory'] = selectedCategory;
    this.setState({ fields });
  }

  render() {

    const { message, hospitalList, hospital } = this.state;
    const { seletctedCategory } = this.state.fields;
    const { categories } = this.state;

    console.log('hospitalhospitalhospital', hospital)
    return (
      <SiteWrapperSuperAdmin>
        <Page.Content>
          <Grid.Row>
            <Grid.Col lg={5} className="mx-auto ">
              <Card>
                <Card.Header>
                  <Card.Title>Edit Agent</Card.Title>
                </Card.Header>
                <Card.Body>
                  {!!message.text ?
                    <div className={'alert alert-' + message.style} >
                      <p>{message.text}</p>
                      <Icon prefix="fa" name="times" onClick={this.handleNotification}></Icon>
                    </div>
                    :
                    null
                  }
                  <form onSubmit={this.handleSubmit}>
                    <Form.FieldSet>
                      <Form.Group
                        isRequired
                        label="Agent Name"
                      >
                        <Form.Input name="name"
                          onChange={(e) => this.handleChange(e, 'name')}
                          placeholder='Agent Name'
                          value={this.state.fields.name} />
                        <label style={{ display: this.state.errors.name ? 'block' : 'none' }} className="error">{this.state.errors.name}</label>
                      </Form.Group>


                      <Form.Group
                        isRequired
                        label="Email ID"
                      >
                        <Form.Input name="email"
                          onChange={(e) => this.handleChange(e, 'email')}
                          placeholder='Email Id'
                          value={this.state.fields.email} />
                        <label style={{ display: this.state.errors.email ? 'block' : 'none' }} className="error">{this.state.errors.email}</label>
                      </Form.Group>
                      <Form.Group
                        isRequired
                        label="Agent Id"
                      >
                        <Form.Input name="agentId"
                          onChange={(e) => this.handleChange(e, 'agentId')}
                          placeholder='Agent Id'
                          value={this.state.fields.agentId} />
                        <label style={{ display: this.state.errors.agentId ? 'block' : 'none' }} className="error">{this.state.errors.agentId}</label>
                      </Form.Group>
                      {/* <label className="form-label">Language<span className="form-required">*</span></label> */}

                      <div>
                        <p>Please select Agent Type*</p>

                        <input type="radio" id="chatAgent" name="agentType" value="chatAgent"
                          checked={this.state.fields.agentType == "chatAgent"}
                          onChange={(e) => this.handleChange(e, 'agentType')}
                        />
                        <label for="chatAgent">Chat Agent</label>
                        <br />



                        <input type="radio" id="leadAgent" name="agentType" value="leadAgent" checked={this.state.fields.agentType == "leadAgent"}
                          onChange={(e) => this.handleChange(e, 'agentType')}
                        />
                        <label for="leadAgent">Lead Agent</label>
                        <label style={{ display: this.state.errors.agentType ? 'block' : 'none' }} className="error">{this.state.errors.agentType}</label>

                      </div>
                      {/* <Select name="category"
                        value={seletctedCategory}
                        onChange={this.selectCategory}
                        options={categories}
                      /> */}



                    </Form.FieldSet>
                    {/* <Form.Group label='Hospital'>
                      <select value={this.state.fields.hospital}
                        onChange={(e) => this.handleChange(e, 'hospital')}>
                        <option>

                          Please select hospital
                        </option>
                        {
                          hospitalList &&
                          hospitalList.map((result) => {
                            return (
                              <option value={result.id}>
                                {result.name_en}
                              </option>
                            )
                          })
                        }


                      </select>
                      <label style={{ display: this.state.errors.hospital ? 'block' : 'none' }} className="error">{this.state.errors.hospital}</label>
                    </Form.Group> */}

                    {!this.state.isLoading ?
                      <Button color="primary btn-block" type="submit">Update</Button>
                      :
                      <Buttonloader loading={this.state.isLoading}></Buttonloader>
                    }
                  </form>
                  <ToastContainer autoClose={5000} position={'bottom-right'} />
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapperSuperAdmin >
    )
  }

}

export default ClientEdit;

