import React from "react";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import {
  Grid,
  Card,
  Table,
  Page,
  colors,
  Form
} from "tabler-react";
import { updateDoctorImage } from "../../services/user";


class ImageModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.openImageModal,
      fileObj: null,
      reload: false
    };
    //this.openModal = this.props.openPopup;
    this.closeImageModal = this.closeImageModal.bind(this);

    this.onfileChange = this.onfileChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  closeImageModal(reload = false) {
    this.props.onChange(false, reload);
  }

  submitReply(e) {
    this.props.onChange(false);
  }

  onfileChange(e) {
    if (e.target.files && e.target.files.length && e.target.files[0]) {
      this.setState({ fileObj: e.target.files[0] })
    }

  }

  async handleSubmit(e) {
    try {
      e.preventDefault();

      if (this.state.fileObj) {
        let formData = new FormData()
        formData.append("media", this.state.fileObj)
        updateDoctorImage(this.props.openImageModal.data, formData).then(async(res) => {
          await this.setState({ reload: true })
          console.log(res)
          toast.success("Campaign Image uploaded successfully.")
          this.closeImageModal(true)

        }).catch(error => {
          if (error.response && error.response.data.message)
            toast.error(error.response.data.message)
          else if (error.message)
            toast.error(error.message)
          else
            toast.error(error)
        })


        // const res = await clientService.

      }
    } catch (error) {
      console.error(error)
    }
  }


  render() {
    // console.log('admin-engage-score',this.props.openImageModal.data)
    const openImageModal = this.props.openImageModal.open;
    const replyOption = this.props.openImageModal.reply || this.state.reply
    const message = this.props.openImageModal.data;
    console.log('admin-engage-score', this.props, this.props.openImageModal, this.props.openImageModal.open, this.props.openImageModal.data)
    return (
      <div>

        <Popup
          open={openImageModal}
          onClose={this.closeImageModal}
          closeOnDocumentClick
        >


          <div className="card">
            <div className="card-header">

              <div className="card-options">
                <a className="card-options-remove cursorPointer"
                  data-toggle="card-remove"
                  onClick={() => {
                    this.closeImageModal();
                  }}>
                  <i className="fe fe-x"></i>
                </a>
              </div>
            </div>
            <form className="card-body aligncenter" onSubmit={this.handleSubmit}>

              <div>
                <label htmlFor="file">Select An Image:</label>
                <input type="file" id="file" onChange={this.onfileChange} />
              </div>
              <button type="submit">Submit</button>

            </form>

          </div>

        </Popup>
      </div>
    );
  }
}

export default ImageModal