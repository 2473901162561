// @flow

import React from "react";
import { Button, Grid, Card, Table, Form, Page, Icon } from "tabler-react";
import Select from "react-select";
import { Link, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SiteWrapperSuperAdmin from "./SiteWrapper.superadmin.react";
import clientService from "../services/client";
import userService from "../services/user";
import Bot from "../services/bot";
import authActions from "../redux/auth/action";
import { validate } from "../validate";
import Buttonloader from "../common/Loader/Buttonloader";
import CsvErrorMesage from "./CsvErrorMessage";
import { changeLanguage } from "../../src/language/index";
import $ from "jquery";
import Moment from "moment";
import UploadNotificaiton from "./UploadNotification";

const validationRules = [
  {
    field: "name",
    validations: ["max:50"],
    name: "Name",
  },
  {
    field: "countryCode",
    validations: ["required"],
    name: "Country Code",
  },
  {
    field: "contactNumber",
    validations: ["required", "numeric"],
    name: "Contact Number",
  },
  {
    field: "email",
    validations: ["email"],
    name: "Email",
  },
  {
    field: "userType",
    validations: ["required"],
    name: "User Type",
  },
];

class ShareData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: "erp",
      currentPage: 1,
      users: [],
      pageLoading: true,
      currentPage: this.props.match.params.page,
      nextPage: false,
      prevoiusPage: false,
      selectedFile: "",
      selectedImages: "",
      csvTotalUsers: 0,
      erpTotalUsers: 0,
      manualTotalUsers: 0,
      csvTotalUsersTab: 0,
      erpTotalUsersTab: 0,
      manualTotalUsersTab: 0,
      userTypes: [],
      clientId: this.props.match.params.clientId,
      selectedOptionUserType: {},
      fileName: changeLanguage(this.props.siteLanguage, "select_file"),
      templateType: ["image", "video", "audio", "text", "file", "interactive button"],
      selectedTemplateType: {},
      refreshing: false,

      fields: {
        name: "",
        contactNumber: "",
        email: "",
        userType: "",
        countryCode: "91",
      },
      message: {
        style: "success",
        text: "",
      },
      dynamicFields: [],
      errors: {},
      isLoading: false,
      openPopupCsv: false,
      values: "",
      selectedFileUser: "",
    };

    this.handleTabActive = this.handleTabActive.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.onChangeFileUser = this.onChangeFileUser.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCreateUser = this.handleCreateUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setLoadingToggle = this.setLoadingToggle.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  componentDidMount() {
    if (this.props.user.role === "admin") {
      this.props.history.push(`/client/${this.state.clientId}/analytics`);
    }
    document.title = "Voiceoc | Dashboard";
    this.pagination(false, this.state.currentPage, this.state.viewType);
    this.getUserTypes();
  }

  // getUserTypes(){

  //   userService.getUserTypes(1,false,10000,this.state.clientId)
  //     .then(res => {
  //       if (res.statusCode === 200) {
  //         var firstObj = {};
  //         var typeValue = '';
  //         if(res.data.result.length){
  //           firstObj = {
  //             label: res.data.result[0].name,
  //             value: res.data.result[0]._id
  //           }
  //           typeValue = res.data.result[0].name;
  //         }
  //         const { fields } = this.state;
  //         fields['userType'] = typeValue;
  //         this.setState(() => ({
  //           userTypes: res.data.result,
  //           selectedOptionUserType: firstObj,
  //           fields
  //         }));
  //       }
  //     }).catch(error => {

  //     })
  // }

  getUserTypes() {
    Bot.getTempltes()
      .then((res) => {
        if (res.statusCode === 200) {
          this.setState(() => ({
            userTypes: res.data.result,
          }));
        }
      })
      .catch((error) => {});
  }

  handleTabActive = (tab) => {
    this.setState({
      viewType: tab,
      currentPage: 1,
    });

    // if (tab === 'erp' || tab === 'manual') {
    //   this.state.users = [];
    // }
    // else {
    this.pagination(false, 1, tab);
    //}
  };

  callService(page, tab = "erp") {
    clientService
      .getNotification(page, tab)
      .then((res) => {
        if (res.statusCode === 200) {
          // debugger
          let erp = res.data.notificationCount.find((x) => x.notificationType === "erp");
          let manual = res.data.notificationCount.find((x) => x.notificationType === "manual");
          let csv = res.data.notificationCount.find((x) => x.notificationType === "csv");

          this.setState(() => ({
            users: res.data.result,
            pageLoading: false,
            nextPage: res.data.next,
            prevoiusPage: this.state.currentPage > 1 ? true : false,
            csvTotalUsers: res.data.total,
            erpTotalUsers: res.data.total,
            manualTotalUsers: res.data.total,
            csvTotalUsersTab: csv ? csv.count : 0,
            erpTotalUsersTab: erp ? erp.count : 0,
            manualTotalUsersTab: manual ? manual.count : 0,
          }));
        }
      })
      .catch((error) => {
        this.setState(() => ({
          pageLoading: false,
        }));
      });
  }

  pagination(e = false, page, tab) {
    this.callService(page, tab);
    this.props.history.push("/admin/" + this.state.clientId + "/dashboard/" + page);
  }

  formReset() {
    document.getElementById("csvForm").reset();
  }

  onChangeFileUser = (event) => {
    // debugger
    console.log(event.target.files[0].type)
    if (
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "image/jpg" ||
      event.target.files[0].type === "image/png" ||
      event.target.files[0].type === "application/pdf" ||
      event.target.files[0].type === "video/mp4" 
    ) {
      // data.append('clientId', this.props.match.params.clientId);
      this.setState({ selectedFileUser: event.target.files[0] });
    } else {
      toast.error("Please select only image");
      this.formReset();
    }
  };
  onChangeFile = (event) => {
    // debugger
    // var data = new FormData();

    if (event.target.id === "image") {
      if (
        event.target.files[0].type === "application/pdf" ||
        event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/jpg" ||
        event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "video/mp4"
      ) {
        this.setState({ selectedImages: event.target.files });
      } else {
        toast.error("Please select image file to upload");
        this.formReset();
        return false;
      }
    } else {
      // if() {
      let arr = event.target.files[0].name.split(".");
      console.log("arrrrr", arr);
      if (arr[1] === "csv" || arr[1] === "xls" || arr[1] === "xlsx") {
        this.setState({ selectedFile: event.target.files[0], fileName: event.target.files[0].name });
      } else {
        toast.error("Please select image file to upload");
        this.formReset();
        return false;
      }
      // }
    }
  };

  //submit form
  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.state.selectedFile === null || this.state.selectedFile == "") {
      toast.error("Please select csv file to upload");
      this.formReset();
      return false;
    } else {
      let data = new FormData();
      if (this.state.selectedImages !== null || this.state.selectedImages != "") {
        for (let i = 0; i < this.state.selectedImages.length; i++) {
          await data.append(`media`, this.state.selectedImages[i]);
        }
      }

      await data.append("notification_file", this.state.selectedFile);
      await clientService
        .notificationImportByCsv(data)
        .then((response) => {
          if (response.data.length) {
            var arr = response.data.map(function(val) {
              return ++val;
            });
            const errorRows = arr.join();
            this.openCsvPopup("Rows " + errorRows + " were not uploaded, please correct the data & try again. Thanks!");
          } else {
            toast.success("User imported successfully.");
          }
          this.setState(() => ({
            fileName: "select file",
          }));
          this.pagination(false, 1, this.state.viewType);
          this.setState({ refreshing: !this.state.refreshing });

          this.formReset();
        })
        .catch((error) => {
          // console.log(error.response);
          //toast.error(error.response.data.message)
        });
    }
  };

  openCsvPopup(mesage) {
    this.setState({
      openPopupCsv: {
        open: true,
        data: mesage,
      },
    });
  }

  closePopup(data) {
    this.setState({
      openPopupCsv: {
        open: false,
        data: false,
      },
    });
  }

  //submit user creation form
  handleCreateUser = async (event) => {
    event.preventDefault();
    if (this.validate()) {
      const queationValues = this.validateQuestions();
      console.log("queationValues", queationValues);
      console.log("queationValues", this.state.dynamicFields);
      // debugger
      // if(queationValues && queationValues.length){
      this.setLoadingToggle(true);

      let data = new FormData();

      let objFinal = {};
      let obj = {};
      let result = this.state.dynamicFields.forEach((value, index) => {
        data.append(value.value, queationValues[index]);
        // obj[value.value] = queationValues[index];
        // objFinal = {
        //   ...obj
        // }
      });

      data.append("phoneNumber", this.state.fields.countryCode + this.state.fields.contactNumber);
      data.append("countryCode", this.state.fields.countryCode);

      // data.append('template_type', this.state.selectedTemplateType.value);
      // console.log('template_type', this.state.selectedTemplateType)
      data.append("event", this.state.selectedOptionUserType.label);
      data.append("media", this.state.selectedFileUser);

      // let data = {
      //   contactNumber: this.state.fields.contactNumber,
      //   userType: this.state.fields.userType,
      //   countryCode: this.state.fields.countryCode,
      //   clientId: this.props.user.contactNumber
      // }

      // if(this.state.fields.name){
      //   data['name'] = this.state.fields.name
      // }
      // if(this.state.fields.email){
      //   data['email'] = this.state.fields.email
      // }

      clientService
        .notificationCreate(data)
        .then((response) => {
          toast.success("User created successfully.");
          this.setLoadingToggle(false);

          var firstObj = {};
          var typeValue = "";
          //reset select box
          if (this.state.userTypes.length) {
            firstObj = {
              label: this.state.userTypes[0].name,
              value: this.state.userTypes[0]._id,
            };
            typeValue = this.state.userTypes[0].name;
          }

          const { fields } = this.state;
          fields["userType"] = typeValue;
          fields["countryCode"] = "91";

          this.setState(() => ({
            pageLoading: true,
            fields: {
              name: "",
              contactNumber: "",
              countryCode: "91",
              email: "",
              userType: typeValue,
            },
            selectedOptionUserType: firstObj,
            dynamicFields: [],
          }));
          this.callService(1, this.state.viewType);
        })
        .catch((error) => {
          //this.setState(() => ({ message: { text: error.response.data.message, style: 'danger' } }));
          setTimeout(() => {
            this.handleNotification();
          }, 3000);
          this.setLoadingToggle(false);
        });
    }
  };

  validateQuestions() {
    var options = [];
    $(".template-option").each(function(index, ele) {
      var $optionele = $(ele).next();
      let textclass = $(ele)
        .find(".textclass")
        .val();
      //      let optionvalue = $(ele).find('.optionvalue').val();

      //  let data = {
      //     "text": textclass.toString(),

      //   }
      if (textclass == "") {
        options = [];
        return false;
      }
      options.push(textclass.toString());
    });
    console.log("options", options);
    return options;
  }

  //handle notificatiion
  handleNotification() {
    this.setState(() => ({ message: { text: "", style: "" } }));
  }

  //handle change
  handleChange = (e, field) => {
    const { fields } = this.state,
      val = e.target.value;
    fields[field] = val;
    this.setState({ fields });
    //this.validate();
  };

  validate() {
    const { fields } = this.state;
    const { isValid, errors } = validate(fields, validationRules);
    this.setState({ errors });
    return isValid;
  }

  setLoadingToggle(value) {
    this.setState({ isLoading: value });
  }

  //   inputField  = () =>{
  //  const optionLength = $('.template-option').length+1;
  //     const optionHtml = '<div class="template-option_'+optionLength+' template-option"><fieldset class="form-fieldset"><div id="all_options"><div class="form-group"><label class="form-label">Option '+optionLength+'<span class="form-required">*</span></label><input name="text[]" class="form-control textclass" type="text" placeholder="Text" value=""></div><i class="fa fa-trash delete_option" data-optionid="'+optionLength+'" id="delete_option_'+optionLength+'" style="display: block;"></i></div></fieldset></div>';

  //   }
  fieldChanged = (fieldId, value) => {
    this.setState({
      values: value,
    });
    //   setValues(currentValues => {
    //       currentValues[fieldId] = value;
    //       return currentValues;
    // }
    // );
  };
  handleChangeUserType = (selectedOptionUserType) => {
    // debugger
    const { fields } = this.state;
    fields["userType"] = selectedOptionUserType.label;
    //optionUserTypes // selectedOptionUserType.value
    let dynamicData = this.state.userTypes.find((x) => x._id === selectedOptionUserType.value).dynamicFields;

    let res = dynamicData.map((value, index) => {
      return {
        value: value,
        index: index,
      };
    });

    //   const newValues = res.fields.reduce((obj, field) => {

    //     obj[index] = "";
    //     return obj;
    // }, {});
    console.log("newValues", res);
    this.setState({
      selectedOptionUserType: selectedOptionUserType,
      dynamicFields: res,
      fields,
    });
  };

  getPagination() {
    var recordEndTo = 0;
    var totalRecord = 0;
    var page = this.props.match.params.page;
    var startRecord = this.props.match.params.page == 1 ? this.props.match.params.page : 10 * (this.props.match.params.page - 1) + 1;
    var type = this.state.viewType;
    if (type === "erp") {
      totalRecord = this.state.erpTotalUsers;
    }
    if (type === "manual") {
      totalRecord = this.state.manualTotalUsers;
    }
    if (type === "csv") {
      totalRecord = this.state.csvTotalUsers;
    }
    if (this.state.users.length < 1) {
      return "0-0 of " + totalRecord;
    } else {
      var endRecord = parseInt(startRecord) + parseInt(this.state.users.length) - 1;
      return startRecord + "-" + endRecord + " of " + totalRecord;
    }
    console.log(this.state.users.length);

    // return this.state.users.length +' of '+totalRecord;
  }

  render() {
    const { users } = this.state;
    const { pageLoading } = this.state;
    const { nextPage } = this.state;
    const { fileName } = this.state;
    const { message } = this.state;
    const { userTypes } = this.state;
    // const optionUserTypes = userTypes.reduce((result, {template_name, _id,status}) => [...result, ...status =='active' ? [{ label: template_name, value: _id}] : []], []);
    // const optionUserTypes = userTypes.reduce((result, {template_name, _id,status}) => [...result, ...status =='active' ? [{ label: template_name, value: _id}] : []], []);
    const optionUserTypes = userTypes.map((elm) => ({ label: elm.templateName, value: elm._id }));

    console.log("optionUserTypes", optionUserTypes);
    const { selectedOptionUserType } = this.state;

    return (
      <SiteWrapperSuperAdmin>
        <Page.Content>
          <Grid.Row>
            <Grid.Col lg={6}>
              <Card>
                <Card.Header className="alignselfcenter">
                  <div className="tabs">
                    <Card.Title className={this.state.viewType === "erp" ? "active_tab" : ""}>
                      <Link to="#" onClick={(e) => this.handleTabActive("erp")}>
                        {changeLanguage(this.props.siteLanguage, "erp")}({this.state.erpTotalUsersTab})
                      </Link>
                    </Card.Title>
                    <Card.Title className={this.state.viewType === "csv" ? "active_tab" : ""}>
                      <Link to="#" onClick={(e) => this.handleTabActive("csv")}>
                        {changeLanguage(this.props.siteLanguage, "csv")}({this.state.csvTotalUsersTab})
                      </Link>
                    </Card.Title>
                    <Card.Title className={this.state.viewType === "manual" ? "active_tab" : ""}>
                      <Link to="#" onClick={(e) => this.handleTabActive("manual")}>
                        {changeLanguage(this.props.siteLanguage, "manual")}({this.state.manualTotalUsersTab})
                      </Link>
                    </Card.Title>
                  </div>
                </Card.Header>
                <Table cards={true} striped={true} responsive={true} className="table-vcenter ">
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>{changeLanguage(this.props.siteLanguage, "sl_no")}</Table.ColHeader>
                      {/* <Table.ColHeader >{changeLanguage(this.props.siteLanguage,'name')}</Table.ColHeader> */}
                      <Table.ColHeader>{changeLanguage(this.props.siteLanguage, "mobile_number")}</Table.ColHeader>
                      <Table.ColHeader>Template Name</Table.ColHeader>
                      <Table.ColHeader>Date</Table.ColHeader>
                      <Table.ColHeader>Message Status</Table.ColHeader>
                      {/* <Table.ColHeader>{changeLanguage(this.props.siteLanguage,'status')}</Table.ColHeader> */}
                    </Table.Row>
                  </Table.Header>
                  {!pageLoading ? (
                    <Table.Body>
                      {users.length ? (
                        users.map((user, index) => (
                          <Table.Row key={index}>
                            <Table.Col>{index + 1}</Table.Col>
                            {/* <Table.Col>{user.name}</Table.Col> */}
                            <Table.Col className="text-nowrap">{user.phoneNumber}</Table.Col>
                            <Table.Col className="text-nowrap">{user.event}</Table.Col>
                            <Table.Col className="text-nowrap">{user.createdAt && Moment(user.createdAt).format("DD/MM/YY h:mm a")}</Table.Col>
                            <Table.Col className="text-nowrap">
                              {user.status}
                              {/* {user.status === 'read' ? <i className='fe fe-check-square'></i> 
                                : user.status === 'sent' ? <i className='fe fe-check'></i> 
                                : <i className='fe fe-check-circle'></i>
                            } */}
                            </Table.Col>
                          </Table.Row>
                        ))
                      ) : (
                        <Table.Row>
                          <Table.Col colSpan={7}>{changeLanguage(this.props.siteLanguage, "sorry_user_not_found")}</Table.Col>
                        </Table.Row>
                      )}
                    </Table.Body>
                  ) : (
                    <Table.Body>
                      <Table.Row>
                        <Table.Col className="pageloader" colSpan={7}>
                          Loading.
                        </Table.Col>
                      </Table.Row>
                    </Table.Body>
                  )}
                </Table>
                {/* pagination */}
                <div className="form-group" style={{ float: "right" }}>
                  <label className="form-label" />
                  <div className="selectgroup align-to-right paginations">
                    <span>{this.getPagination()}</span>

                    <i
                      className={"fe " + (this.props.match.params.page <= 1 ? "not-active " : "cursorPointer ") + " fe-chevron-left"}
                      onClick={(e) => this.pagination(e, parseInt(this.props.match.params.page) - 1, this.state.viewType)}
                      disabled={this.props.match.params.page <= 1}
                    />

                    <i className={"fe " + (!nextPage ? "not-active " : "cursorPointer ") + " fe-chevron-right"} onClick={(e) => this.pagination(e, parseInt(this.props.match.params.page) + 1, this.state.viewType)} disabled={!nextPage} />
                  </div>
                </div>
              </Card>
              <br />
              <UploadNotificaiton refreshData={this.state.refreshing} />
            </Grid.Col>
            <Grid.Col lg={6}>
              <Card>
                <Card.Header>
                  <Card.Title> {changeLanguage(this.props.siteLanguage, "user_create_form")}</Card.Title>
                </Card.Header>
                <Card.Body>
                  {!!message.text ? (
                    <div className={"alert alert-" + message.style}>
                      <p>{message.text}</p>
                      <Icon prefix="fa" name="times" onClick={this.handleNotification} />
                    </div>
                  ) : null}
                  <form onSubmit={this.handleCreateUser}>
                    <Form.FieldSet>
                      {/* <Form.Group
                  
                  label={changeLanguage(this.props.siteLanguage,'user_name')}
                >
                  <Form.Input name="name"
                    onChange={(e) => this.handleChange(e, 'name')}
                    placeholder={changeLanguage(this.props.siteLanguage,'user_name')}
                    value={this.state.fields.name} />
                  <label style={{ display: this.state.errors.name ? 'block' : 'none' }} className="error">{this.state.errors.name}</label>
                </Form.Group> */}

                      <div className="row">
                        <div className="col-xs-3 col-md-3">
                          <Form.Group isRequired label={changeLanguage(this.props.siteLanguage, "country_code")}>
                            <Form.Input name="countryCode" onChange={(e) => this.handleChange(e, "countryCode")} placeholder={changeLanguage(this.props.siteLanguage, "country_code")} value={this.state.fields.countryCode} />
                            <label style={{ display: this.state.errors.countryCode ? "block" : "none" }} className="error">
                              {this.state.errors.countryCode}
                            </label>
                          </Form.Group>
                        </div>

                        <div className="col-xs-9 col-md-9">
                          <Form.Group isRequired label={changeLanguage(this.props.siteLanguage, "contact_number")}>
                            <Form.Input name="contactNumber" onChange={(e) => this.handleChange(e, "contactNumber")} placeholder={changeLanguage(this.props.siteLanguage, "contact_number")} value={this.state.fields.contactNumber} />
                            <label style={{ display: this.state.errors.contactNumber ? "block" : "none" }} className="error">
                              {this.state.errors.contactNumber}
                            </label>
                          </Form.Group>
                        </div>
                        {/* <div className="col-xs-12 col-md-12">
                          <label className="form-label">{changeLanguage(this.props.siteLanguage, 'template_type')}<span className="form-required">*</span></label>

                          <Select
                            value={this.state.selectedTemplateType}
                            onChange={(e)=>this.setState({selectedTemplateType:e})}
                            options={this.state.templateType.map(el=>({value:el,label:el}))}
                            defaultValue={this.state.selectedTemplateType}
                          />
                          <label style={{ display: this.state.errors.userType ? 'block' : 'none' }} className="error">{this.state.errors.userType}</label>

                        </div> */}
                      </div>

                      {/* <Form.Group
                  
                  
                  label="Email ID"
                >
                  <Form.Input name="email"
                    onChange={(e) => this.handleChange(e, 'email')}
                    placeholder='Email Id'
                    value={this.state.fields.email} />
                  <label style={{ display: this.state.errors.email ? 'block' : 'none' }} className="error">{this.state.errors.email}</label>
                </Form.Group> */}

                      <div className="form-group">
                        <label className="form-label">
                          {changeLanguage(this.props.siteLanguage, "template")}
                          <span className="form-required">*</span>
                        </label>
                        <Select value={selectedOptionUserType} onChange={this.handleChangeUserType} options={optionUserTypes} defaultValue={selectedOptionUserType} />
                        <label style={{ display: this.state.errors.userType ? "block" : "none" }} className="error">
                          {this.state.errors.userType}
                        </label>
                      </div>

                      <div className="all_options">
                        {this.state.dynamicFields &&
                          this.state.dynamicFields.length > 0 &&
                          this.state.dynamicFields.map((val, index) => {
                            return (
                              <div className={`template-option_${index + 1} template-option`}>
                                <Form.FieldSet className="del109">
                                  <div>
                                    <Form.Group isRequired label={val.value} className="options">
                                      <Form.Input name="options[]" placeholder="Text" className="textclass" />
                                    </Form.Group>
                                  </div>
                                </Form.FieldSet>
                              </div>
                            );
                          })}
                        <div className="file">
                          <span className="file-custom">
                            <input name="example-text-input" type="file" onChange={(e) => this.onChangeFileUser(e)} />
                          </span>
                        </div>
                      </div>
                    </Form.FieldSet>

                    {!this.state.isLoading ? (
                      <Button color="btn btn-primary btn-block   btn-block aligncentre" type="submit">
                        {changeLanguage(this.props.siteLanguage, "create")}
                      </Button>
                    ) : (
                      <Buttonloader loading={this.state.isLoading} addNewClass="col-lg-4 aligncentre" />
                    )}
                  </form>
                  <ToastContainer autoClose={5000} position={"bottom-right"} />
                </Card.Body>
              </Card>
              <Card>
                <Card.Header>
                  <Card.Title>{changeLanguage(this.props.siteLanguage, "upload_using_csv")}</Card.Title>
                </Card.Header>
                <Link className="backcolor link-w" to="../../../demo/testfile.csv" download target="_blank">
                  {changeLanguage(this.props.siteLanguage, "click_to_download")}
                </Link>
                <Table cards={true} striped={true} className="table-vcenter table-re">
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader className="text-tranform" />
                    </Table.Row>
                  </Table.Header>
                </Table>
                <form id="csvForm" onSubmit={this.handleSubmit.bind(this)}>
                  <Form.FieldSet className="col-lg-12">
                    <Form.Group label="" className="upload-cont">
                      <div className="row card-header">
                        <div className="col-sm-12">
                          <span className="form-label"> Csv Upload:</span>
                          <div className="file">
                            <span className="file-custom">
                              <input id="csv" name="example-text-input" accept="text/csv" type="file" onChange={(e) => this.onChangeFile(e)} />
                            </span>
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <span className="form-label">Images Upload:- </span>
                          <div className="file">
                            <span className="file-custom">
                              <input id="image" name="image" type="file" accept="image/*,application/pdf,video/mp4" multiple onChange={(e) => this.onChangeFile(e)} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                    <Button color="primary btn-block " className=" btn-block aligncentre" type="submit">
                      {changeLanguage(this.props.siteLanguage, "import")}
                    </Button>
                  </Form.FieldSet>
                </form>
              </Card>
            </Grid.Col>
            <CsvErrorMesage openPopup={this.state.openPopupCsv} onChange={this.closePopup} />
          </Grid.Row>
        </Page.Content>
        <ToastContainer autoClose={5000} position={"bottom-right"} />
      </SiteWrapperSuperAdmin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    siteLanguage: state.auth.siteLanguage,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    logOut: () => {
      dispatch(authActions.authLogout());
    },
  };
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShareData)
);
