// @flow

import React from "react";
import { Form, Grid, Card, Button, Table, Icon, Page } from "tabler-react";
import Select from "react-select";
import $ from "jquery";

import Popup from "reactjs-popup";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SiteWrapper from "../SiteWrapper.admin.react";
import SiteWrapperSuperAdmin from "../SiteWrapper.superadmin.react";
import { validate } from "../../validate";
import clientService from "../../services/client";
import botService from "../../services/bot";
import userService from "../../services/user";
import authActions from "../../redux/auth/action";
import Buttonloader from "../../common/Loader/Buttonloader";
import Loader from "../../common/Loader/loader";
import BotEdit from "../../admin/bots/BotEdit";
import ViewTemplate from "./view-template";
import TemplateEdit from "./edit-template";
import { changeLanguage } from "../../../src/language/index";

const validationRules = [
  {
    field: "templateName",
    validations: ["required", "min:1", "max:50"],
    name: "Category",
  },
  {
    field: "text",
    validations: ["required", "min:1"],
    name: "Hsm Message",
  },
  {
    field: "templateId",
    validations: ["required", "min:1", "max:50"],

    name: "Template Id",
  },
  {
    field: "templateType",
    validations: ["required", "min:1", "max:50"],

    name: "Template Type",
  },
];

class TemplateCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        templateName: "",
        templateId: "",
        text: "",
        selectedTemplateType: {},
      },
      isLoading: false,
      templates: [],
      deleteFirstO: false,
      message: {
        style: "success",
        text: "",
      },
      selectedTemplateId: "",
      errors: {},
      addTemplateForm: false,
      editedTemplate: false,
      templateEditedData: {},
      openPopupViewTemplate: false,
      openPopupEditTemplate: false,
      deleteTemplateId: "",
      templateType: ["image", "text", "file", "video"],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.setLoadingToggle = this.setLoadingToggle.bind(this);
    this.closeEditForm = this.closeEditForm.bind(this);
    this.openViewTemplatePopup = this.openViewTemplatePopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.openEditTemplatePopup = this.openEditTemplatePopup.bind(this);
    this.closeEditPopup = this.closeEditPopup.bind(this);
    this.editTemplate = this.editTemplate.bind(this);
    this.deleteButton = this.deleteButton.bind(this);
  }

  openViewTemplatePopup(e, data) {
    this.setState({
      openPopupViewTemplate: {
        open: true,
        data: data,
      },
    });
  }

  closePopup(data) {
    this.setState({
      openPopupViewTemplate: {
        open: false,
        data: false,
      },
    });
  }

  openEditTemplatePopup(e, data) {
    this.setState({
      openPopupEditTemplate: {
        open: true,
        data: data,
      },
    });
  }

  closeEditPopup(data) {
    this.setState({
      openPopupEditTemplate: {
        open: false,
        data: false,
      },
    });
  }

  deleteButton(template, id) {
    if (template === "show_modal") {
      this.setState({
        modalStatus: true,
        deleteTemplateId: id,
      });
    } else if (template === "close_modal") {
      this.setState({
        modalStatus: false,
        deleteTemplateId: "",
      });
    } else {
      console.log(template);
      botService
        .deleteTemplate(this.state.deleteTemplateId)
        .then((response) => {
          toast.success("Template deleted successfully.");
          this.getTemplates();

          this.setState({
            modalStatus: false,
            deleteTemplateId: "",
          });
        })
        .catch((error) => {
          toast.error(error);
          this.setLoadingToggle(false);
        });
    }
  }
  //call bots api
  getTemplates() {
    // let tempData =  [
    //   {
    //     "_id": "5f86967b93bacb2f4b07bfae",
    //     "status": "active",
    //     "category": "Button Text 1",
    //     "description": "Hello 🤚 {{1}},\n\nSanskar Educational Group 🏫 here,\n\nThe registration process for B.Pharm admissions 🎓 is about to get closed\n\nType & Send yes if you are still looking for admissions in B.Pharm\n\nIf not, type & send no",
    //     "templateId": "button Name 1",
    //     "options": [
    //       {
    //         "_id": "5f8696dd93bacb2f4b07bfb6",
    //         "text": "Yes",
    //         "value": "1"
    //       },
    //       {
    //         "_id": "5f8696dd93bacb2f4b07bfb7",
    //         "text": "No",
    //         "value": "2"
    //       }
    //     ],
    //     "templateNo": 4,
    //     "createdAt": "2020-10-14T06:11:07.235Z"
    //   },
    //   {
    //     "_id": "5f86965193bacb2f4b07bfab",
    //     "status": "active",
    //     "category": "Button Text 2",
    //     "description": "Hello 🤚 {{1}},\n\nSanskar Educational Group 🏫 here,\n\nThe registration process for MBA admissions 🎓 is about to get closed\n\nType & Send yes if you are still looking for admissions in MBA\n\nIf not, type & send no",
    //     "templateId": "button Name 2",
    //     "options": [
    //       {
    //         "_id": "5f8696ca93bacb2f4b07bfb4",
    //         "text": "Yes",
    //         "value": "1"
    //       },
    //       {
    //         "_id": "5f8696ca93bacb2f4b07bfb5",
    //         "text": "No",
    //         "value": "2"
    //       }
    //     ],
    //     "templateNo": 3,
    //     "createdAt": "2020-10-14T06:10:25.402Z"
    //   },
    //   {
    //     "_id": "5f782c6ca2da6c1d1bee759f",
    //     "status": "active",
    //     "category": "Button Text 3",
    //     "description": "Hello ✋ {{1}},\n\nSGIT SOM here,\n\nThe counselling process for GGSIPU, Delhi is about to get started.\n\nTo help you facilitate in the counselling process, please\n\ntype & send yes if you have appeared in CET exam and\n\nif not, type & send no",
    //     "templateId": "button Name 3",
    //     "options": [
    //       {
    //         "_id": "5f782c6ca2da6c1d1bee75a0",
    //         "text": "Yes",
    //         "value": "1"
    //       },
    //       {
    //         "_id": "5f782c6ca2da6c1d1bee75a1",
    //         "text": "No",
    //         "value": "2"
    //       }
    //     ],
    //     "templateNo": 2,
    //     "createdAt": "2020-10-03T07:46:52.805Z"
    //   },
    //   {
    //     "_id": "5f0da5ee87c9115274edaed8",
    //     "status": "active",
    //     "category": "Button Text 4",
    //     "description": "Hello 🖐️!\n\nSanskar Educational Group here,\n\nTo get all the info about your desired course, type & send hi",
    //     "templateId": "button Name 4",
    //     "options": [
    //       {
    //         "_id": "5f0da5ee87c9115274edaed9",
    //         "text": "hi",
    //         "value": "1"
    //       }
    //     ],
    //     "templateNo": 1,
    //     "createdAt": "2020-07-14T12:32:46.451Z"
    //   }
    // ];

    // this.setState({templates:tempData})

    this.setLoadingToggle(true);
    botService
      .getTempltes()
      .then((res) => {
        if (res.statusCode === 200) {
          this.setState(() => ({
            templates: res.data.result,
          }));
          this.setLoadingToggle(false);
        }
      })
      .catch((error) => {
        this.setLoadingToggle(false);
      });
  }

  //handle change
  handleChange = (e, field) => {
    console.log(e, field);
    const { fields } = this.state,
      val = e.value ? e : e.target.value;
    fields[field] = val;
    this.setState({ fields });
    //this.validate();
  };

  setLoadingToggle(value) {
    this.setState({ isLoading: value });
  }

  //bot form toggle
  templateFormToggle() {
    this.setState({ addTemplateForm: (this.state.addTemplateForm = !this.state.addTemplateForm) });
  }

  //submit form
  handleSubmit = async (event) => {
    console.log("queationValues");

    event.preventDefault();
    if (this.validate()) {
      const queationValues = this.validateQuestions();
      // if(queationValues && queationValues.length){
      let data = {
        text: this.state.fields.text,
        templateName: this.state.fields.templateName,
        templateId: this.state.fields.templateId,
        templateType: this.state.fields.selectedTemplateType.label,

        dynamicFields: queationValues,
      };

      console.log(data);
      this.setLoadingToggle(true);
      botService
        .createTemplate(data)
        .then((response) => {
          toast.success("Template created successfully.");
          this.getTemplates();
          this.templateFormToggle();
          this.setState({
            fields: {
              templateName: "",
              text: "",
            },
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          this.setLoadingToggle(false);
        });
    } else {
      toast.error("Please fill up all options");
    }
    // }
  };

  //validateQuestions
  validateQuestions() {
    var options = [];
    $(".template-option").each(function(index, ele) {
      var $optionele = $(ele).next();
      let textclass = $(ele)
        .find(".textclass")
        .val();
      //      let optionvalue = $(ele).find('.optionvalue').val();

      //  let data = {
      //     "text": textclass.toString(),

      //   }
      if (textclass == "") {
        options = [];
        return false;
      }
      options.push(textclass.toString());
    });
    console.log("options", options);
    return options;
  }

  handleNotification() {
    this.setState(() => ({ message: { text: "", style: "" } }));
  }

  validate() {
    const { fields } = this.state;
    const { isValid, errors } = validate(fields, validationRules);
    this.setState({ errors });
    return isValid;
  }

  componentDidMount() {
    document.title = "Voiceoc | Manage Template";
    let _self = this;
    // $(document).unbind().on('click', '.addoptions,.addoptionsdynamic', function(event){
    //   event.preventDefault();
    //   var divId = $(this).attr('id');
    //   if(divId !== undefined){
    //     var splitDiv = divId.split('_');
    //     //$('#'+divId).remove();
    //     _self.addNewOption(event, splitDiv[1]);
    //   }

    // });

    //delete question
    $(document).on("click", ".delete_question", function(event) {
      _self.deleteQuestion(event);
    });

    //delete option
    $(document).on("click", ".delete_option", function(event) {
      _self.deleteOption(event, $(this));
    });
    //call api
    this.getTemplates();
  }

  deleteOption(event, $this) {
    var ele = $(".all_options");
    let _self = this;
    $this
      .parent()
      .parent()
      .parent()
      .remove();
    this.manageOptionSequence(ele);
    const optionLength = $(".template-option").length;
    if (optionLength < 2) {
      $(".delete_option").hide();
    }
  }

  manageOptionSequence(ele) {
    $($(ele))
      .children()
      .each(function(index, element) {
        var text = $(element)
          .find(".form-label")
          .text();
        var count = index + 1;
        $(element)
          .find(".form-label")
          .html("Option " + count + '<span class="form-required">*</span>');
      });
  }

  deleteQuestion(event) {
    $("#" + event.target.id)
      .parent(".questions")
      .remove();
    $("#questions_" + event.target.dataset.quesid).remove();
    $("#delete_option_" + event.target.dataset.quesid).remove();
    this.manageSequence();
  }

  //manage question sequesnce
  manageSequence() {
    var quesLength = $(".questions").length;
    $($(".questions")).each(function(index) {
      var ele = $(".questions")[index];
      var text = $(ele)
        .find(".form-label")
        .text();
      var count = index + 1;
      $(ele)
        .find(".form-label")
        .html("Question " + count + '<span class="form-required">*</span>');
      if (quesLength === 1) {
        $(ele)
          .find(".delete_question")
          .hide();
      } else {
        $(ele)
          .find(".delete_question")
          .show();
      }
    });
  }

  addNewOption(event, id) {
    this.setState({
      deleteFirstO: true,
    });
    //<i class="fa fa-plus addoptionsdynamic" id="question_'+id+'"></i>

    event.preventDefault();
    const optionLength = $(".template-option").length + 1;
    const optionHtml =
      '<div class="template-option_' +
      optionLength +
      ' template-option"><fieldset class="form-fieldset"><div id="all_options"><div class="form-group options"><label class="form-label">Option ' +
      optionLength +
      '<span class="form-required">*</span></label><input name="options[]" class="form-control textclass" type="text" placeholder="Text" value=""></div><i class="fa fa-trash delete_option" data-optionid="' +
      optionLength +
      '" id="delete_option_' +
      optionLength +
      '" style="display: block;"></i></div></fieldset></div>';
    // const optionHtml = '<div class="template-option_'+optionLength+' template-option"><fieldset class="form-fieldset"><div id="all_options"><div class="form-group options"><label class="form-label">Option '+optionLength+'<span class="form-required">*</span></label><input name="text[]" class="form-control textclass" type="text" placeholder="Text" value=""></div><div class="form-group options"><input name="optionvalue[]" class="form-control optionvalue" type="text" placeholder="Value" value=""></div><i class="fa fa-trash delete_option" data-optionid="'+optionLength+'" id="delete_option_'+optionLength+'" style="display: block;"></i></div></fieldset></div>';

    $(".all_options").append(optionHtml);
    if (optionLength > 1) {
      $(".delete_option").show();
    }
  }

  //toggle bot status
  statusToggle(bot) {
    const toggleData = bot.status === "active" ? "block" : "active";
    let data = {
      status: toggleData,
    };
    this.setLoadingToggle(true);
    botService
      .blockUnblock(bot._id, data)
      .then((response) => {
        toast.success("Bot " + toggleData + " successfully.");
        this.getTemplates();
        this.setLoadingToggle(false);
      })
      .catch((error) => {
        this.setLoadingToggle(false);
        toast.error(error.response.data.message);
      });
  }

  //toggle bot status
  editTemplate(template) {
    //this.closeAddForm();
    //remove new blank question
    //$('.newaddedquestion').remove();
    this.setState({
      addTemplateForm: false,
      editedTemplate: false,
    });

    this.setLoadingToggle(true);
    // botService.getBot(bot._id)
    //   .then(response => {
    let _self = this;
    setTimeout(function() {
      _self.setState({
        editedTemplate: true,
        addTemplateForm: false,
        templateEditedData: template,
      });
      _self.setLoadingToggle(false);
    }, 500);

    //console.log(this.state.templateEditedData);
    //   this.setLoadingToggle(false);
    // })
    // .catch(error => {
    //   this.setLoadingToggle(false);
    //   toast.error(error.response.data.message)
    // })
  }

  //close edit form
  closeEditForm(data = false) {
    this.setState({
      addTemplateForm: false,
      editedTemplate: false,
    });
    this.getTemplates();
  }

  //close add form
  closeAddForm() {
    this.closeEditForm();
  }

  render() {
    const { message } = this.state;
    const { templates } = this.state;
    const { addTemplateForm } = this.state;
    const { editedTemplate } = this.state;
    const { templateEditedData } = this.state;

    return (
      <SiteWrapperSuperAdmin>
        <Page.Content className="bots">
          <div className="card card01a">
            <Card>
              <Card.Header>
                <Card.Title>{changeLanguage(this.props.siteLanguage, "templates")}</Card.Title>
                {!this.state.editedTemplate ? (
                  <Button color="primary btn-block add-bot marginright" type="button" onClick={(e) => this.templateFormToggle()}>
                    {/* Add Template */}
                    {changeLanguage(this.props.siteLanguage, "add_template")}
                  </Button>
                ) : null}
              </Card.Header>
              <Table cards={true} responsive={true} className="table-vcenter table table-btn tab21">
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader className="">{changeLanguage(this.props.siteLanguage, "sl_no")}</Table.ColHeader>
                    <Table.ColHeader className="tem0t1">{changeLanguage(this.props.siteLanguage, "template_name")}</Table.ColHeader>
                    <Table.ColHeader className="tem0t1">{changeLanguage(this.props.siteLanguage, "templateId")}</Table.ColHeader>
                    <Table.ColHeader className="tem0t1">{changeLanguage(this.props.siteLanguage, "template_type")}</Table.ColHeader>

                    <Table.ColHeader className="tem0t2">{changeLanguage(this.props.siteLanguage, "hsm_message")}</Table.ColHeader>
                    <Table.ColHeader className="">{changeLanguage(this.props.siteLanguage, "action")}</Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {templates.length ? (
                    templates.map((template, index) => (
                      <Table.Row key={index}>
                        <Table.Col>{index + 1}</Table.Col>
                        {/* <Table.Col className="w-2">
                        <Avatar imageURL="../images/9.jpg" />
                      </Table.Col> */}
                        <Table.Col>{template.templateName}</Table.Col>
                        <Table.Col>{template.templateId}</Table.Col>
                        <Table.Col>{template.templateType}</Table.Col>

                        <Table.Col>{template.text}</Table.Col>
                        <Table.Col className="text-nowrap actions">
                          <a className="cursorPointer col-bl" onClick={(e) => this.openViewTemplatePopup(e, template)}>
                            <i className="fe fe-eye" />
                          </a>
                          <a className="cursorPointer col-bl" onClick={(e) => this.editTemplate(template)}>
                            <i className="fe fe-edit" />
                          </a>
                          <a className="cursorPointer col-bl" onClick={(e) => this.deleteButton("show_modal", template._id)}>
                            <i className="fe fe-trash" />
                          </a>
                        </Table.Col>
                        <Popup className="modal2" open={this.state.modalStatus} onClose={this.closeModal} closeOnDocumentClick>
                          Do You want to delete?
                          <br />
                          <br />
                          <button onClick={() => this.deleteButton(template._id)} className="btn btn-primary">
                            {" "}
                            Yes{" "}
                          </button>
                          <button onClick={() => this.deleteButton("close_modal")} className="btn btn-primary">
                            {" "}
                            No{" "}
                          </button>
                        </Popup>
                      </Table.Row>
                    ))
                  ) : (
                    <Table.Row>
                      <Table.Col colSpan={7}>Sorry! Templates not found.</Table.Col>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </Card>
          </div>
          <Grid.Row>
            <Grid.Col lg={12} className="mx-auto ">
              <Card>
                <form onSubmit={this.handleSubmit} id="template-create-form">
                  {!!addTemplateForm ? (
                    <Grid.Row>
                      <Card.Body className="col col-lg-11 mx-auto row">
                        <div className="bot-details col col-lg-9">
                          <Form.Group isRequired label={changeLanguage(this.props.siteLanguage, "templateId")} className="cat-name">
                            <Form.Input name="templateId" onChange={(e) => this.handleChange(e, "templateId")} placeholder={changeLanguage(this.props.siteLanguage, "templateId")} value={this.state.fields.templateId} />

                            <label style={{ display: this.state.errors.templateName ? "block" : "none" }} className="error">
                              {this.state.errors.templateName}
                            </label>
                          </Form.Group>

                          <Form.FieldSet>
                            <div className="bot-details col col-lg-4">
                              <label className="form-label">
                                {changeLanguage(this.props.siteLanguage, "template_type")}
                                <span className="form-required">*</span>
                              </label>
                              <Select
                                value={this.state.fields.selectedTemplateType}
                                onChange={(e) => this.setState({ fields: { ...this.state.fields, selectedTemplateType: e } })}
                                options={this.state.templateType.map((el) => ({ value: el, label: el }))}
                                defaultValue={this.state.fields.selectedTemplateType}
                              />
                              <label style={{ display: this.state.errors.userType ? "block" : "none" }} className="error">
                                {this.state.errors.userType}
                              </label>
                            </div>

                            {/* <Form.Group
                            isRequired
                            label={changeLanguage(this.props.siteLanguage,'template_id')}
                            className="rowtem-id"
                          >
                            <Form.Input name="templateId"
                              onChange={(e) => this.handleChange(e, 'templateId')}
                              placeholder={changeLanguage(this.props.siteLanguage,'template_id')}
                              value={this.state.fields.templateId} />

                            <label style={{ display: this.state.errors.templateId ? 'block' : 'none' }} className="error">{this.state.errors.templateId}</label>
                          </Form.Group> */}

                            <Form.Group isRequired label={changeLanguage(this.props.siteLanguage, "template_name")} className="cat-name">
                              <Form.Input name="templateName" onChange={(e) => this.handleChange(e, "templateName")} placeholder={changeLanguage(this.props.siteLanguage, "template_name")} value={this.state.fields.templateName} />

                              <label style={{ display: this.state.errors.templateName ? "block" : "none" }} className="error">
                                {this.state.errors.templateName}
                              </label>
                            </Form.Group>

                            <div className="all_options">
                              <div className="template-option_1 template-option">
                                <Form.FieldSet className="del109">
                                  <div>
                                    <Form.Group
                                      // isRequired
                                      label="Option 1"
                                      className="options"
                                    >
                                      <Form.Input name="options[]" placeholder="Text" className="textclass" />
                                    </Form.Group>
                                    {/* <Form.Group
                               
                                label="Option 1" className="options">
                                <Form.Input name="options[]"
                                  placeholder='Value'
                                  className="optionvalue"
                                  /> 
                              </Form.Group> */}
                                    <i className="fa fa-trash delete_option" style={{ display: this.state.deleteFirstO ? "block" : "none" }} data-optionid="1" id="delete_option_1" />
                                  </div>
                                </Form.FieldSet>
                              </div>
                            </div>

                            <Form.Group isRequired label={changeLanguage(this.props.siteLanguage, "hsm_message")} className="hsm-m hsm-m0">
                              <Form.Textarea name="text" rows="10" onChange={(e) => this.handleChange(e, "text")} placeholder={changeLanguage(this.props.siteLanguage, "hsm_message")} className="hsm-m1" value={this.state.fields.text} />

                              <label style={{ display: this.state.errors.text ? "block" : "none" }} className="error">
                                {this.state.errors.text}
                              </label>
                            </Form.Group>
                          </Form.FieldSet>
                        </div>

                        {!this.state.isLoading ? (
                          <div className="btn_set btn-10 col-lg-3">
                            <Button className="aligncentre btn btn-primary " type="submit">
                              {changeLanguage(this.props.siteLanguage, "save")}
                            </Button>
                            <Button className="aligncentre btn btn-secondary  " type="button" onClick={(e) => this.closeAddForm(e)}>
                              {changeLanguage(this.props.siteLanguage, "cancel")}
                            </Button>
                          </div>
                        ) : (
                          <Buttonloader loading={this.state.isLoading} addNewClass="col-lg-4 aligncentre" />
                        )}

                        <div className="">
                          <div className="d-flex">
                            <button className="btn btn-primary ml-auto" type="button" onClick={(e) => this.addNewOption(e, 1)}>
                              Add new Option
                            </button>
                          </div>
                        </div>
                      </Card.Body>
                    </Grid.Row>
                  ) : null}
                </form>
                {editedTemplate ? <TemplateEdit templateEditedData={this.state.templateEditedData} onCancelEditForm={this.closeEditForm} /> : null}
              </Card>
            </Grid.Col>
          </Grid.Row>
          <Loader loading={this.state.isLoading} />
          <ToastContainer autoClose={5000} position={"bottom-right"} />
        </Page.Content>
        <ViewTemplate openPopup={this.state.openPopupViewTemplate} onChange={this.closePopup} />
      </SiteWrapperSuperAdmin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    siteLanguage: state.auth.siteLanguage,
  };
};
const bindActions = (dispatch) => {
  return {
    updateToastState: (payload) => dispatch(authActions.updateToastState(payload)),
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    bindActions
  )(TemplateCreate)
);
//export default BotCreate;
